import { Container, Form, FormButton, FormInput } from "../styles";
import { useEffect, useState } from "react";

const Login = () => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    window.addEventListener('load', () => {
        setEmail("")
        setPassword("")
    })

    const handleLogin = () => {
        if(email === "admin" && password === "admin") {
            localStorage.setItem("account", email)
            localStorage.setItem("password", password)
            window.location.href = "/"
        }
        else {
            alert("Usuário ou senha incorretos")
            setEmail("")
            setPassword("")
        }
    }

    useEffect(() => {
        document.title = "Login - LARHCO - Instituto de Química da UFRJ";

    }, [])

    return ( 
        <div>
            <Container>
                <Form>
                    <FormInput type="text" placeholder="E-mail" onChange={(e) => {setEmail(e.target.value); e.preventDefault()}} value={email}/>
                    <FormInput type="password" placeholder="Senha" onChange={(e) => setPassword(e.target.value)} value={password}/> 
                    <FormButton onClick={handleLogin}>Entrar</FormButton>
                </Form>
            </Container>
        </div>
     );
}
 
export default Login;