import styled from "styled-components";
import logo from "../assets/grey-logo.png";

import { useTranslation, Trans } from 'react-i18next';

const Footer = () => {

    const FooterDiv = styled.div`
        width: 100vw;
        height: 500px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding-top: 50px;
        @media (max-width: 767px){
            width: 100vw;
            padding: 50px;
            height: 750px;
        }
        `
        
    const Footer = styled.div`
        width: 1000px;
        height: 400px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: column;
        @media (min-width: 768px) and (max-width: 1100px){
            width: 100vw;
            padding: 30px;
        }
        @media (max-width: 767px){
            width: 100vw;
            padding: 20px;
            height: 100%;
        }
    `
    
    const Contato = styled.div`
        position: relative;
        width: 100%;
        height: 80px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        @media (max-width: 767px){
            width: 100vw;
            padding: 20px;
            height: 210px;
        }
    `

    const Endereço = styled.div`
        width: 100%;
        height: 100px;
        display: flex;
        align-items: flex-start;
        justify-content: space-around;
        flex-direction: column;
        @media (max-width: 767px){
            width: 100vw;
            padding: 20px;
            margin-top: 10px;
            height: 270px;
        }
    `

    const ContatoButton = styled.a`
        width: 235px;
        height: 65px;
        text-decoration: none;
        background: linear-gradient(103.39deg, #0058DB 23.75%, #009FE3 100.26%);
        box-shadow: 0px 3px 12px rgba(74, 58, 255, 0.18);
        border-radius: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        font-size: 18px;
      // line-height: 20px;
        text-align: center;
        color: #FFFFFF;
        position: absolute; 
        right: 0;
        cursor: pointer;
        transition: all 0.5s;
        &:hover {
            transform: scale(1.06);
        }
        @media (max-width: 767px){
            display: none;
        }
    `

    const EndingFooter = styled.div`
        width: 750px;
        height: 120px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        @media (max-width: 767px){
            width: 100vw;
            padding: 20px;
            text-align: center;
            height: 250px;
        }
    `

    const FooterH1 = styled.h1`
        font-weight: 700;
        font-size: 20px;
      // line-height: 35px;
        color: #1E1E1E;
    `

    const FooterP = styled.p`
        font-weight: 400;
        font-size: 16px;
        // line-height: 30px;
        color: #6F6C90;

    `

    const FooterLogo = styled.img`
        width: 110px;
        height: 22px;
        margin-bottom: 10px;
    `

    const AllRights = styled.p`
        margin-top: 15px;
        font-weight: 400;
        font-size: 14px;
        // line-height: 30px;
        text-align: center;
        color: #6F6C90;
    `

    const ChangeLanguageDiv = styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        margin: 40px 0;
        @media (max-width: 767px){
            margin-top: 50px;
            margin-bottom: 50px;
        }`

        const ChangeLanguageBtn = styled.button`
        background: none;
        border: none;
        margin: 0 20px;
        text-transform: uppercase;
        color: #1E1E1E;
        transition: all 0.2s;
        cursor: pointer;
        &:hover {
            color: #0091DB !important;
        }
        `

    const { i18n } = useTranslation();

    const lngs = {
        pt: { nativeName: 'Português' },
        en: { nativeName: 'English' }
      };

    return ( 
        <FooterDiv>
            <Footer>
                <Contato>
                    <FooterH1><Trans i18nKey='footerContactTitle'>Contato</Trans></FooterH1>
                    <FooterP><Trans i18nKey='footerContactDesc'>Estamos a disposição para informações e novos projetos</Trans></FooterP>
                    <ContatoButton href="/contato"><Trans i18nKey='footerContactButton'>Fale com a gente</Trans></ContatoButton>
                </Contato>
                <Endereço>
                    <FooterH1><Trans i18nKey='footerAddress'>Endereço</Trans></FooterH1>
                    <FooterP>Polo de Xistoquímica</FooterP>
                    <FooterP>Rua Hélio de Almeida, 40. Pólo de Xistoquímica, Cidade Universitária, Rio de Janeiro - RJ.</FooterP>
                    <FooterP>CEP: 21941-614, Brasil</FooterP>
                </Endereço>
                <ChangeLanguageDiv>
                {Object.keys(lngs).map((lng) => (
                    <ChangeLanguageBtn key={lng} style={{ fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal', color: i18n.resolvedLanguage === lng ? '#0091DB' : '#1E1E1E' }} type="submit" onClick={() => {i18n.changeLanguage(lng); window.location.reload()}}>
                        {lngs[lng].nativeName}
                    </ChangeLanguageBtn>
                    ))}
                </ChangeLanguageDiv>
                <EndingFooter>
                    <FooterLogo src={logo} alt="logo" />
                    <FooterP>Laboratório de Reatividade de Hidrocarbonetos, Biomassa e Catálise</FooterP>
                    <FooterP>Instituto de Química - UFRJ</FooterP>
                    <AllRights>Todos os direitos reservados © 2022</AllRights>
                </EndingFooter>
            </Footer>
        </FooterDiv>
     );
}
 
export default Footer;