import { Trans } from 'react-i18next';
import { Container, ContainerTitle, ContainerDescription, FormButton, FormInput, FormInputs, ButtonsDiv, DeleteButton } from "../styles";
import styled from "styled-components";
import {useState, useEffect} from "react";
import lab1 from "../assets/lab1.png";
import lab2 from "../assets/lab2.png";
import img1 from "../assets/img1.png";
import img2 from "../assets/img2.png";
import img3 from "../assets/img3.png";
import img4 from "../assets/img4.png";
import img5 from "../assets/img5.png";
import img6 from "../assets/img6.png";

const About = () => {

    const [account, setAccount] = useState({});
    const [hasPermission, setHasPermission] = useState(false);
    const [equipaments, setEquipaments] = useState([])

    useEffect(() => {
        document.title = "Laboratório - LARHCO - Instituto de Química da UFRJ";

        const localStorageAccount = localStorage.getItem("account")
        const localStoragePassword = localStorage.getItem("password")

        if (localStorageAccount === "admin" && localStoragePassword === "admin") {
            setAccount({
                account: localStorageAccount,
                password: localStoragePassword
            })

            console.log(account)
setHasPermission(true)

        } else {
            setAccount({
                account: "",
                password: ""
            })

            setHasPermission(false)
        }

        fetch("https://api-larhco.vercel.app/api/equips")
        .then(res => res.json())
        .then(data => setEquipaments(data))
    }, [])

    const [id, setId] = useState(0);
    const [name, setName] = useState("");
    const [photo, setPhoto] = useState("");
    const [type, setType] = useState("equip");

    const handleFile = async () => {

        const file = document.getElementById('file').files[0];

        let body = new FormData()
        body.set('key', '8bfbdf15eed781a8475a520584a4b18c')
        body.append('image', file)
    
        return fetch('https://api.imgbb.com/1/upload', {
            method: 'POST',
            body
        })
        .then(response => response.json())
        .then(result => {
            console.log(result.data.url)
            setPhoto(result.data.url)
        }
        )

      }

    const [buttonVisibility, setButtonVisibility] = useState("none")

    useEffect (() => {
        if (photo !== "") {
            setButtonVisibility("auto")
        }
    }, [photo])

    const handleSubmit = (id) => {
        if (id !== 0){
            console.log('é pra editar', id)
            return handleEdit(id);
        }

        const equip = {
            name: name,
            photo: photo,
            type: type
        }

        console.log(equip)
        
        fetch("https://api-larhco.vercel.app/api/equips", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(equip)
        })
        .then(res => res.json())
        .then(response => response.json())
        .then(setTimeout(() => window.location.reload(), 1500))
    }

    const handleDelete = (id) => {
        fetch(`https://api-larhco.vercel.app/api/equips/${id}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(setTimeout(() => window.location.reload(), 1500))
    }

    const handleEdit = (id) => {
        const equip = {
            name: name,
            photo: photo,
            type: type
        }

        console.log(equip)

        fetch(`https://api-larhco.vercel.app/api/equips/${id}`, {
            method: "DELETE"
        })
        .then(response => response.json())
        .then(fetch("https://api-larhco.vercel.app/api/equips", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(equip)
            })
            .then(response => response.json())
            .then(setTimeout(() => window.location.reload(), 1500)))
    }

    const AddEquipButton = styled(FormButton)`
        width: 200px;
        display: ${buttonVisibility};
    `

    const [deleteButtonVisibility, setDeleteButtonVisibility] = useState('none');

    useEffect(() => {
        if (id !== 0) {
            setDeleteButtonVisibility('flex')
        } else {
            setDeleteButtonVisibility('none')
        }
    }, [id])


    const DeleteEquipButton = styled(DeleteButton)`
        width: 200px;
        display: ${deleteButtonVisibility};
    `

    const AddPhotoButton = styled.label`
        width: 200px;
        height: 64px;
        background: linear-gradient(103.39deg, #0058DB 23.75%, #009FE3 100.26%);
        box-shadow: 0px 3px 12px rgba(74, 58, 255, 0.18);
        border-radius: 56px;    
        font-weight: 400;
        font-size: 18px;
      // line-height: 20px;
        text-align: center;
        margin-top: 20px;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    `

    const EquipamentDiv = styled.div`
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
    `

    const EquipamentItem = styled.div`
        width: 430px;
        @media (min-width: 768px) and (max-width: 1100px){
            width: calc(50% - 20px);
        }
    `

    const EquipamentImage = styled.img`
        width: 100%;
        height: 230px;
        filter: drop-shadow(0px 5px 14px rgba(8, 15, 52, 0.15));
        border-radius: 18px;
        object-fit: cover;
        @media (max-width: 768px){
        border-radius: 8px;
        }
    `        

    const EquipamentName = styled.p`
        font-weight: 400;
        font-size: 16px;
      // line-height: 30px;
        color: #1E1E1E;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 15px;
    `

    const MiniPToEdit = styled.p`
        font-size: 14px;
        // line-height: 16px;
        color: #000000;
        margin: 0;
        margin-bottom: 10px;
        cursor: pointer;
    `

    const ImagesDiv = styled.div`
        width: 1000px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        @media (min-width: 768px) and (max-width: 1100px){
            width: 100% ;
        }
        @media (max-width: 768px){
            width: 100%;
            flex-direction: column;
            display: none;
        }
    `

    const LabImages = styled.img`
        width: 100%;
        height: 370px;
        filter: drop-shadow(0px 5px 14px rgba(8, 15, 52, 0.25));
        border-radius: 18px;
        object-fit: cover;
        @media (min-width: 768px) and (max-width: 1100px){
            border-radius: 18px;
            width: calc(50% - 20px);
        }
        @media (max-width: 768px){
            width: 100%;
            flex-direction: column;
            border-radius: 8px;
            margin: 10px 0;
        }
    `

    const ContainerDescriptionB = styled(ContainerDescription)`
        font-weight: 800;
        margin-top: 20px;
    `

    const FormSelect = styled.select`
        background: #FFFFFF;
        border: 1px solid #514F6E;
        border-radius: 14px;
        width: 430px;
        height: 58px;
        // // margin: 11px;
        padding: 18px;
        &:focus {
            outline: none;
            border: 1px solid #0091DB;
            box-shadow: 0px 0px 4px #0091DB;
        }
        @media (min-width: 768px) and (max-width: 1100px){
            width: 200px;
        }
        @media (max-width: 767px){
            width: 100%;
            // margin: 11px 0;
        }
    `       
    const AreaImage = styled.img`
        margin: auto;
        @media (max-width: 768px){
            width: 100% !important;
        }
    `      

    const PDFButton = styled.a`
        width: auto;
        padding: 0 40px;
        height: 65px;
        text-decoration: none;
        background: linear-gradient(103.39deg, #0058DB 23.75%, #009FE3 100.26%);
        box-shadow: 0px 3px 12px rgba(74, 58, 255, 0.18);
        border-radius: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        font-size: 18px;
      // line-height: 20px;
        text-align: center;
        color: #FFFFFF;
        margin: auto;
        cursor: pointer;
        transition: all 0.5s;
        &:hover {
            transform: scale(1.06);
        }
        @media (max-width: 767px){
            display: none;
        }
    `
    
    return ( 
        <div className='main-div'>

            {hasPermission && (
                <Container>
                    <ContainerTitle>Novo equipamento</ContainerTitle>
                    <FormInputs style={{justifyContent: 'flex-start'}}>
                            <FormInput type="text" placeholder="Nome" onChange={(e) => setName(e.target.value)} value={name}/>
                    </FormInputs>
                    <FormSelect onChange={(e) => setType(e.target.value)} value={type}>
                        <option value="equip">Equipamentos</option>
                        <option value="place">Ambientes</option>
                    </FormSelect>
                    <ButtonsDiv>
                        <div style={{display: 'flex', flexDirection: 'row', width: '440px', justifyContent: 'space-between'}}>
                            <DeleteEquipButton onClick={() => handleDelete(id)}>Excluir</DeleteEquipButton>
                            <AddPhotoButton htmlFor="file">Escolher foto</AddPhotoButton>
                            <input style={{display:'none'}} type="file" name='arquivo' id="file" onChange={handleFile}/>
                        </div>
                        <AddEquipButton onClick={() => handleSubmit(id)}>Adicionar</AddEquipButton>
                    </ButtonsDiv>
                </Container>
            )}
            
            <Container>
                <ContainerTitle><Trans i18nKey='aboutLabTitle'>Sobre o laboratório</Trans></ContainerTitle>
                <ContainerDescription><Trans i18nKey='aboutLab'>O Laboratório de Reatividade de Hidrocarbonetos, Biomassa e Catálise (LARHCO) está localizado no Polo de Xistoquímica do Instituto de Química da UFRJ. Fundado em 1997 e coordenado pelo professor Claudio Jose de Araujo Mota, apresenta como principal área de atuação a catálise heterogênea e o desenvolvimento de matérias para aplicações em energia e ambiente. </Trans></ContainerDescription>
                <ContainerDescriptionB><Trans i18nKey='aboutLabBold1'>Missão</Trans></ContainerDescriptionB>
                <ContainerDescription><Trans i18nKey='aboutLab1'>Formar recursos humanos de alto nível e realizar pesquisa científico-tecnológica nas áreas de catálise e materiais aplicados à energia e ao meio ambiente, contribuindo para o avanço da ciência, o desenvolvimento econômico e o bem-estar da sociedade.</Trans></ContainerDescription>
                <ContainerDescriptionB><Trans i18nKey='aboutLabBold2'>Visão</Trans></ContainerDescriptionB>
                <ContainerDescription><Trans i18nKey='aboutLab2'>Ser um laboratório de excelência em pesquisa, reconhecido nacional e internacionalmente, nas áreas de energia e meio ambiente, disseminando conhecimento e desenvolvendo tecnologias para aproveitamento pelo setor industrial.</Trans></ContainerDescription>
                <ContainerDescriptionB><Trans i18nKey='aboutLabBold3'>Valores</Trans></ContainerDescriptionB>
                <ContainerDescription><Trans i18nKey='aboutLab3A'>Integração entre todas as partes envolvidas na pesquisa</Trans></ContainerDescription>
                <ContainerDescription><Trans i18nKey='aboutLab3B'>Eficiência e confiabilidade das análises</Trans></ContainerDescription>
                <ContainerDescription><Trans i18nKey='aboutLab3C'>Inovação e competência científica e tecnológica</Trans></ContainerDescription>
                {/* <ContainerDescriptionB><Trans i18nKey='aboutLabBold4'>Captura e Conversão de CO2</Trans></ContainerDescriptionB>
                <ContainerDescription><Trans i18nKey='aboutLab4A'>O CO2 é o grande vilão das mudanças climáticas, sendo emitido na queima de combustíveis fósseis. A captura e conversão do CO2 é um assunto atual e de grande relevância científico-tecnológica. </Trans></ContainerDescription>
                <ContainerDescription><Trans i18nKey='aboutLab4B'>Na linha de captura de CO2 o grupo desenvolve adsorventes funcionalizados para a captura de CO2 de correntes gasosas, incluindo a captura direta do ar atmosférico. Os estudos são focados em materiais baseados em biomassa, que possam apresentar desempenho igual ou superior aos materiais convencionais, baseados em fontes fósseis.</Trans></ContainerDescription>  
                <ContainerDescription><Trans i18nKey='aboutLab4C'>O LARHCO estuda a hidrogenação do CO2 a hidrocarbonetos, sobretudo otimizando a formação de combustível de aviação. Nesta linha são utilizados catalisadores metálicos suportados, que permitem realizar a reação reversa de gás d’água (RWGS) e a síntese de Fischer-Tropsch (FTS) em uma única etapa. </Trans></ContainerDescription>
                <ContainerDescription><Trans i18nKey='aboutLab4D'>Há também estudos de hidrogenação do CO2 a metanol e dimetil-éter (DME). O grupo desenvolve catalisadores de cobre e óxido de zinco, com diferentes promotores catalíticos, visando aumentar a atividade e seletividade. Já na produção de dimetil-éter o foco é desenvolver catalisadores bifuncionais, baseados em cobre e óxido de zinco suportados em materiais ácidos, para desidratar o metanol formado a dimetil-éter. </Trans></ContainerDescription>
                <ContainerDescription><Trans i18nKey='aboutLab4E'>Ainda em conversão de CO2 há estudos sobre produção de carbonatos orgânicos, produtos com inúmeras aplicações industriais. O grupo desenvolve catalisadores heterogêneos baseados em óxidos metálicos para a produção de carbonato de dimetila. Zeólitas e óxidos mesoporosos são estudados como catalisadores para a produção de carbonatos orgânicos cíclicos, como o carbonato de propileno.</Trans></ContainerDescription> */}
            </Container>

            <Container>
                <ContainerTitle><Trans i18nKey='areasTitle'>Linhas de Pesquisa</Trans></ContainerTitle>
                <ContainerDescriptionB><Trans i18nKey='aboutLabBold4'>Captura e Conversão de CO2</Trans></ContainerDescriptionB>
                <ContainerDescription><Trans i18nKey='aboutLab4A'>O CO2 é o grande vilão das mudanças climáticas, sendo emitido na queima de combustíveis fósseis. A captura e conversão do CO2 é um assunto atual e de grande relevância científico-tecnológica. </Trans></ContainerDescription>
                <ContainerDescription><Trans i18nKey='aboutLab4B'>Na linha de captura de CO2 o grupo desenvolve adsorventes funcionalizados para a captura de CO2 de correntes gasosas, incluindo a captura direta do ar atmosférico. Os estudos são focados em materiais baseados em biomassa, que possam apresentar desempenho igual ou superior aos materiais convencionais, baseados em fontes fósseis.</Trans></ContainerDescription>  
                <ContainerDescription><Trans i18nKey='aboutLab4C'>O LARHCO estuda a hidrogenação do CO2 a hidrocarbonetos, sobretudo otimizando a formação de combustível de aviação. Nesta linha são utilizados catalisadores metálicos suportados, que permitem realizar a reação reversa de gás d’água (RWGS) e a síntese de Fischer-Tropsch (FTS) em uma única etapa. </Trans></ContainerDescription>
                <ContainerDescription><Trans i18nKey='aboutLab4D'>Há também estudos de hidrogenação do CO2 a metanol e dimetil-éter (DME). O grupo desenvolve catalisadores de cobre e óxido de zinco, com diferentes promotores catalíticos, visando aumentar a atividade e seletividade. Já na produção de dimetil-éter o foco é desenvolver catalisadores bifuncionais, baseados em cobre e óxido de zinco suportados em materiais ácidos, para desidratar o metanol formado a dimetil-éter. </Trans></ContainerDescription>
                <ContainerDescription><Trans i18nKey='aboutLab4E'>Ainda em conversão de CO2 há estudos sobre produção de carbonatos orgânicos, produtos com inúmeras aplicações industriais. O grupo desenvolve catalisadores heterogêneos baseados em óxidos metálicos para a produção de carbonato de dimetila. Zeólitas e óxidos mesoporosos são estudados como catalisadores para a produção de carbonatos orgânicos cíclicos, como o carbonato de propileno.</Trans></ContainerDescription>
                <AreaImage src={img1} style={{width: '400px', marginTop: '30px'}}></AreaImage> 
                <AreaImage src={img2} style={{width: '400px', marginBottom: '30px'}}></AreaImage> 

                <ContainerDescriptionB><Trans i18nKey='areasBold2'></Trans></ContainerDescriptionB>
                <ContainerDescription><Trans i18nKey='areas2A'></Trans></ContainerDescription>  
                <ContainerDescription><Trans i18nKey='areas2B'></Trans></ContainerDescription>
                <ContainerDescription><Trans i18nKey='areas2C'></Trans></ContainerDescription>
                <AreaImage src={img3} style={{width: '650px', marginTop: '30px', marginBottom: '30px'}}></AreaImage> 
                
                <ContainerDescriptionB><Trans i18nKey='areasBold3'></Trans></ContainerDescriptionB>
                <ContainerDescription><Trans i18nKey='areas3A'></Trans></ContainerDescription>  
                <ContainerDescription><Trans i18nKey='areas3B'></Trans></ContainerDescription>
                <ContainerDescription><Trans i18nKey='areas3C'></Trans></ContainerDescription>
                <ContainerDescription><Trans i18nKey='areas3D'></Trans></ContainerDescription>
                <ContainerDescription><Trans i18nKey='areas3E'></Trans></ContainerDescription>
                <AreaImage src={img4} style={{width: '650px', marginTop: '30px', marginBottom: '30px'}}></AreaImage> 

                <ContainerDescriptionB><Trans i18nKey='areasBold4'></Trans></ContainerDescriptionB>
                <ContainerDescription><Trans i18nKey='areas4A'></Trans></ContainerDescription>  
                <ContainerDescription><Trans i18nKey='areas4B'></Trans></ContainerDescription>
                <AreaImage src={img5} style={{width: '400px', marginTop: '30px'}}></AreaImage> 
                <AreaImage src={img6} style={{width: '400px', marginTop: '30px'}}></AreaImage> 
            </Container>

            <Container>
                <ContainerTitle><Trans i18nKey='placeTitle'>Ambientes</Trans></ContainerTitle>
                <EquipamentDiv>
                    {equipaments.filter(equip => equip.type === 'place').map(equip => (
                        <EquipamentItem>
                            {hasPermission && (
                                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around', width: '100%'}}>
                                    <MiniPToEdit style={{color: '#0091DB'}} onClick={() => {
                                        setId(equip.id)
                                        setPhoto(equip.photo)
                                        setName(equip.name)
                                    }}>Editar</MiniPToEdit>
                                    <MiniPToEdit style={{color: 'red'}} onClick={() => handleDelete(equip.id)}>Excluir</MiniPToEdit>
                                </div>
                            )}
                            <EquipamentImage src={equip.photo} alt={equip.name} />
                            <EquipamentName>{equip.name}</EquipamentName>
                        </EquipamentItem>
                    ))}
                </EquipamentDiv>

            </Container>

            <Container>
                <ContainerTitle><Trans i18nKey='equipmentsTitle'>Equipamentos</Trans></ContainerTitle>
                <EquipamentDiv>
                    {equipaments.filter(equip => equip.type === 'equip').map(equip => (
                        <EquipamentItem>
                            {hasPermission && (
                                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around', width: '100%'}}>
                                    <MiniPToEdit style={{color: '#0091DB'}} onClick={() => {
                                        setId(equip.id)
                                        setPhoto(equip.photo)
                                        setName(equip.name)
                                    }}>Editar</MiniPToEdit>
                                    <MiniPToEdit style={{color: 'red'}} onClick={() => handleDelete(equip.id)}>Excluir</MiniPToEdit>
                                </div>
                            )}
                            <EquipamentImage src={equip.photo} alt={equip.name} />
                            <EquipamentName>{equip.name}</EquipamentName>
                        </EquipamentItem>
                    ))}
                </EquipamentDiv>

            </Container>

            <Container>
                <ContainerTitle><Trans i18nKey='qualityPoliceTitle'>Equipamentos</Trans></ContainerTitle>
                <PDFButton href="https://netuno-larhco.s3.us-east-2.amazonaws.com/Pol%C3%ADtica+da+Qualidade+LARHCO.pdf"><Trans i18nKey='qualityPoliceButton'>Leia nossa política de qualidade</Trans></PDFButton>
            </Container>
        </div>
     );
}
 
export default About;