import styled from "styled-components";

export const Container = styled.div`
    background: #FFFFFF;
    width: 1000px;
    box-shadow: 0px 5px 14px rgba(8, 15, 52, 0.04);
    border-radius: 18px;
    padding: 45px 50px;
    margin: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    &:first-child {
        margin-top: 0;
    }
    @media (min-width: 768px) and (max-width: 1100px){
        width: 100%;
    }
    @media (max-width: 767px){
        width: calc(100% - 20px);
        padding: 30px;
        margin: 10px 10px;
    }
`

export const ContainerTitle = styled.h1`
    font-weight: 700;
    font-size: 20px;
  // line-height: 25px;
    color: #1E1E1E;
    margin-bottom: 26px;
`

export const ContainerBlueTitle = styled.h1`
    font-weight: 700;
    font-size: 18px;
  // line-height: 24px;
    text-align: flex-start;
    margin-bottom: 10px;
    color: #0091DB;
`

export const ContainerItem = styled.div`
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-around;
    flex-direction: column; 
`

export const ContainerDescription = styled.p`
    font-weight: 400;
    font-size: 16px;
  // line-height: 22px;
    text-align: justify;
    color: #1E1E1E;
    margin-bottom: 10px;
`

export const Form = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
`

export const FormInputs = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    @media (max-width: 767px){
        flex-direction: column;
    }
`

export const FormLeft = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    @media (max-width: 767px){
        width: 100%;
    }
`

export const FormInput = styled.input`
    background: #FFFFFF;
    border: 1px solid #514F6E;
    border-radius: 10px;
    width: 430px;
    height: 60px;
    margin: 15px 0;
    transition: all .3s;
    padding: 15px 18px;
    font-size: 16px;
    &:focus {
        outline: none;
        border: 1px solid #0091DB;
        box-shadow: 0px 0px 20px #0091DB70;
    }
    @media (min-width: 768px) and (max-width: 1100px){
        width: calc(100% - 10px);
        margin: 10px 0;
    }
    @media (max-width: 767px){
        width: 100%;
        margin: 10px 0;
    }

`

export const FormTextArea = styled.textarea`
    background: #FFFFFF;
    border: 1px solid #514F6E;
    border-radius: 14px;
    width: 430px;
    height: 150px;
    transition: all .3s;
    padding: 15px 18px;
    font-size: 16px;
    resize: none;
    &:focus {
        outline: none;
        border: 1px solid #0091DB;
        box-shadow: 0px 0px 20px #0091DB70;
    }
    @media (min-width: 768px) and (max-width: 1100px){
        width: calc(50% - 10px);
        height: 140px;
    }
    @media (max-width: 767px){
        width: 100%;
        margin-top: 10px;
    }
    `

export const FormButton = styled.div`
    width: 170px;
    height: 60px;
    background: linear-gradient(103.39deg, #0058DB 23.75%, #009FE3 100.26%);
    box-shadow: 0px 3px 12px rgba(74, 58, 255, 0.18);
    border-radius: 56px;    
    font-weight: 400;
    font-size: 18px;
  // line-height: 20px;
    text-align: center;
    margin-top: 20px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.5s;
    &:hover {
        transform: scale(1.06);
    }
    @media (max-width: 767px){
    }
`

export const ButtonsDiv = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const DeleteButton = styled(FormButton)`
    background: white;
    border: 1px solid #FF0000;
    color: #FF0000;
`
