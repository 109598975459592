import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Container } from '../../styles';
import { Trans } from 'react-i18next';

const HomeSupporter = () => {

    const [supporters, setSupporters] = useState([]);

    useEffect (() => {
        fetch("https://api-larhco.vercel.app/api/supporters")
            .then(res => res.json())
            .then(data => {
                setSupporters(data.slice(0, 8))
            })
    }, [])



    const HomeSupporter = styled.div`
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 80px 0;
        flex-direction: column;
        background-color: #ffffff;
    `

    const SupporterTitle = styled.h1`
        font-size: 24px;
        color: #1e1e1e;
        font-weight: 700;
        margin-left: -800px;
        @media (min-width: 768px) and (max-width: 1100px){
            margin-left: -400px;
        }
        @media (max-width: 768px){
            margin-left: 0px;
        }
    `

    const AllSupporterButton = styled.a`
        background: linear-gradient(103.39deg, #0058DB 23.75%, #009FE3 100.26%);
        box-shadow: 0px 3px 12px rgba(74, 58, 255, 0.18);
        border-radius: 56px;    
        width: 325px; 
        height: 65px;
        display: flex;
        text-decoration: none;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        weight: 400;
        color: #FFFFFF;
        cursor: pointer;
        transition: all 0.5s;
        &:hover {
            transform: scale(1.06);
        }
    `

    const SupportersDiv = styled(Container)`
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        align-content: center;
        width: 1000px;
        margin: 0 auto;
        box-shadow: none;
        @media (max-width: 1100px) {
            width: 100%;
        }
    `

    const SupporterItem = styled.div`
        display: flex;
        flex-direction: column;
        width: 180px;
        justify-content: space-around;
        align-items: center;
        margin-bottom: 20px;
        @media (min-width: 768px) and (max-width: 1100px){
            width: 100px;
        }
        margin: 0 20px;
    `

    const SupporterPhoto = styled.img`
        max-width: 180px;
        max-height: 130px;
        cursor: pointer;
        margin-bottom: 20px;
    `

    return ( 
        <HomeSupporter>
            <SupporterTitle><Trans i18nKey='homeSupporterTitle'>Financiadores e Parceiros</Trans></SupporterTitle>
            <SupportersDiv>
                {supporters.map(supporter => (
                    <SupporterItem key={supporter.id}>
                        <SupporterPhoto src={supporter.photo} alt="Foto do Apoiador" />
                    </SupporterItem>
                ))}
            </SupportersDiv>
            <AllSupporterButton href='/apoio'><Trans i18nKey='homeSupporterButton'>Conheça todos os apoiadores</Trans></AllSupporterButton>
        </HomeSupporter>

     );
}
 
export default HomeSupporter;