import {Container, ContainerTitle, ContainerItem, Form, FormLeft, FormInputs, FormInput, FormTextArea, FormButton } from "../styles";
import {useEffect, useState} from "react";
import { Trans } from 'react-i18next';

const Contact = () => {

    
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const handleContact = () => {
        fetch("https://api-larhco.vercel.app/api/sendemail", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                name: name,
                email: email,
                message: message
            }) 
                
        })
        .then(response => response.json())
        .then(data => {
            if(data.status === "success"){
                alert("Mensagem enviada com sucesso! Obrigado pelo contato.");
                setName("");
                setEmail("");
                setMessage("");
            } else if(data.status === "fail"){
                alert("Mensagem não enviada. Tente novamente mais tarde.");
            }
        })
        
        // .then(setTimeout(() => window.location.reload(), 1500))
    }
    
    useEffect(() => {
        document.title = "Contato - LARHCO - Instituto de Química da UFRJ";
    }, [])

    return ( 
        <div className='main-div'>
            <Container>
                <ContainerTitle><Trans i18nKey='contato'></Trans></ContainerTitle>
                <ContainerItem><Trans i18nKey='contatop1'></Trans>Estamos a disposição para informações e novos projetos. Fale com a gente através do formulário abaixo, responderemos assim que possível.</ContainerItem>
                <Form>
                    <FormInputs>
                        <FormLeft>
                            <FormInput type="text" placeholder="Nome" onChange={(e) => setName(e.target.value)} value={name}/>
                            <FormInput type="text" placeholder="E-mail" onChange={(e) => setEmail(e.target.value)} value={email}/>
                        </FormLeft>
                        <FormTextArea placeholder="Mensagem" onChange={(e) => setMessage(e.target.value)} value={message}/>
                    </FormInputs>
                    <FormButton onClick={handleContact}><Trans i18nKey='enviar'></Trans></FormButton>
                </Form>
            </Container>
            <Container>
                <ContainerTitle><Trans i18nKey='local'></Trans></ContainerTitle>
                <iframe title='location' width="100%" height="330" id="gmap_canvas" src="https://maps.google.com/maps?q=Rua%20H%C3%A9lio%20de%20Almeida,%2040.%20P%C3%B3lo%20de%20Xistoqu%C3%ADmica,%20Cidade%20Universit%C3%A1ria,%20Rio%20de%20Janeiro%20-%20RJ&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
            </Container>
        </div>
     );
}
 
export default Contact;
