import { useState, useEffect } from 'react';
import { Container, ContainerTitle, FormButton, FormLeft, FormInput, FormInputs, ButtonsDiv, DeleteButton } from "../styles";
import styled from 'styled-components';
import editButton from './../assets/editbutton.svg'
import { Trans } from 'react-i18next';

const Team = () => {

    const [account, setAccount] = useState({});
    const [hasPermission, setHasPermission] = useState(false);
    const [teamMembers, setTeamMembers] = useState([]);

    useEffect(() => {
        document.title = "Equipe - LARHCO - Instituto de Química da UFRJ";

        const localStorageAccount = localStorage.getItem("account")
        const localStoragePassword = localStorage.getItem("password")

        if (localStorageAccount === "admin" && localStoragePassword === "admin") {
            setAccount({
                account: localStorageAccount,
                password: localStoragePassword
            })

            console.log(account)
            setHasPermission(true)

        } else {
            setAccount({
                account: "",
                password: ""
            })

            setHasPermission(false)
        }
        
        fetch("https://api-larhco.vercel.app/api/team")
        .then(res => res.json())
        .then(data => {
            setTeamMembers(data)
        })  
    }, [])


    const [memberId, setMemberId] = useState(0);
    const [name, setName] = useState("");
    const [role, setRole] = useState("Coordenador");
    const [description, setDescription] = useState("");
    const [photo, setPhoto] = useState("");
    const [lattes, setLattes] = useState("");

    const handleFile = async () => {

        const file = document.getElementById('file').files[0];

        let body = new FormData()
        body.set('key', '8bfbdf15eed781a8475a520584a4b18c')
        body.append('image', file)
    
        return fetch('https://api.imgbb.com/1/upload', {
            method: 'POST',
            body
        })
        .then(response => response.json())
        .then(result => {
            console.log(result.data.url)
            setPhoto(result.data.url)
        }
        )
      }

      const [buttonVisibility, setButtonVisibility] = useState("none")
  
      useEffect (() => {
          if (photo !== "") {
            setButtonVisibility("auto")
          }
      }, [photo])

    const handleSubmit = (id) => {
        if (id !== 0){
            console.log('é pra editar', id)
            return handleEdit(id);
        }

        const member = {
            name: name,
            role: role,
            description: description,
            photo: photo,
            lattes: lattes
        }

        console.log(member)
        
        fetch("https://api-larhco.vercel.app/api/team", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(member)
        })
        .then(res => res.json())
        // .then(setTimeout(() => window.location.reload(), 1500))
    }

    const handleDelete = (id) => {
        fetch(`https://api-larhco.vercel.app/api/team/${id}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(setTimeout(() => window.location.reload(), 1500))
    }

    const handleEdit = (id) => {
        const member = {
            name: name,
            role: role,
            description: description,
            photo: photo,
            lattes: lattes
        }

        console.log(member)

        fetch(`https://api-larhco.vercel.app/api/team/${id}`, {
            method: "DELETE"
        })
        .then(response => response.json())
        .then(fetch("https://api-larhco.vercel.app/api/team", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(member)
            })
            .then(response => response.json())
            .then(setTimeout(() => window.location.reload(), 1500)))
    }

    const MembersDiv = styled.div`
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        align-content: center;
        width: 1000px;
        margin: 20px auto;
        padding-top: 15px;
        @media (min-width: 768px) and (max-width: 1100px){
            width: 700px;
        }
        @media (max-width: 768px){
            width: 100%;
        }
        position: relative;
    `

    const MemberItem = styled.div`
        display: flex;
        flex-direction: row;
        width: 480px;
        height: 190px;
        justify-content: space-around;
        align-items: center;
        background: #FFFFFF;
        box-shadow: 0px 5px 14px rgba(8, 15, 52, 0.04);
        border-radius: 18px;
        overflow: hidden;
        margin: 20px 0;
        position: relative;
        @media (min-width: 768px) and (max-width: 1100px){
            width: 320px;
            height: 170px;
            margin-right: 30px;
            margin-top: 10px;
        }
        @media (max-width: 768px){
            width: 100%;
            margin: 10px 10px;
            height: 170px;
        }
    `

    const MemberPhoto = styled.img`
        width: 140px;
        height: 210px;
        object-fit: cover;
        @media (min-width: 768px) and (max-width: 1100px){
            height: 190px;
        }
        @media (max-width: 768px){
            height: 190px;
        }
    `
    
    const MemberInfo = styled.div`
        display: flex;
        flex-direction: column;
        padding: 30px 40px;
        width: 480px;
        height: 190px;
        position:relative;
        @media (min-width: 768px) and (max-width: 1100px){
            padding: 30px;
        }
        @media (max-width: 768px){
            padding: 30px;
        }
    `

    const MemberName = styled.h2`
        font-weight: 700;
        font-size: 20px;
        line-height: 20px;
        margin-bottom: 5px;
        color: #1E1E1E;
    `    

    const MemberRole = styled.h3`
        font-weight: 400;
        font-size: 18px;
        // line-height: 10px;
        color: #0091DB;
    `

    const MemberLattes = styled.a`
        font-weight: 400;
        font-size: 16px;
      // line-height: 32px;
        margin-top 30px;
        color: #0091DB;
        position:absolute;
        bottom: 30px;
    `

    const AddPhotoButton = styled.label`
        width: 200px;
        height: 64px;
        background: linear-gradient(103.39deg, #0058DB 23.75%, #009FE3 100.26%);
        box-shadow: 0px 3px 12px rgba(74, 58, 255, 0.18);
        border-radius: 56px;    
        font-weight: 400;
        font-size: 18px;
      // line-height: 20px;
        text-align: center;
        margin-top: 20px;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    `

    const EditButtonImg = styled.img`
        position: absolute;
        margin: 35px;
        right: 0;
        bottom: 0;
        width: 28px;
        height: 28px;
    `


    const AddMemberButton = styled(FormButton)`
        width: 200px;
        display: ${buttonVisibility};
    `

    const [deleteButtonVisibility, setDeleteButtonVisibility] = useState('none');

    useEffect(() => {
        if (memberId !== 0) {
            setDeleteButtonVisibility('flex')
        } else {
            setDeleteButtonVisibility('none')
        }
    }, [memberId])

    const DeleteMemberButton = styled(DeleteButton)`
        width: 200px;
        display: ${deleteButtonVisibility};
    `

    const FormSelect = styled.select`
        background: #FFFFFF;
        border: 1px solid #514F6E;
        border-radius: 14px;
        width: 430px;
        height: 58px;
        // margin: 11px;
        padding: 18px;
        &:focus {
            outline: none;
            border: 1px solid #0091DB;
            box-shadow: 0px 0px 4px #0091DB;
        }
        @media (min-width: 768px) and (max-width: 1100px){
            width: 200px;
        }
        @media (max-width: 767px){
            width: 100%;
            // margin: 11px 0;
        }
    `

    const MemberDescription = styled.a`
        text-decoration: none;
        color: #6F6C8F;
        font-weight: 400;
        margin-top: 0;
        font-size: 16px
        transition: all 0.2s;
        cursor: pointer;
        &:hover {
            color: #0091DB !important;
        }
    `

    return ( 
        <div className='main-div'>

            {hasPermission && (
                <Container>
                    <ContainerTitle>Novo membro</ContainerTitle>
                    <FormInputs>
                        <FormLeft style={{width: '100%', height: '140px', justifyContent: 'center'}}>
                            <FormInput type="text" placeholder="Título" onChange={(e) => setName(e.target.value)} value={name}/>
                            <FormSelect onChange={(e) => setRole(e.target.value)} value={role}>
                                <option value="Coordenador" >Coordenador</option>
                                <option value="Secretária" >Secretária</option>
                                <option value="Professor Associado">Professor Associado</option>
                                <option value="Professor Colaborador">Professor Colaborador</option>
                                <option value="Pesquisador Associado" >Pesquisador Associado</option>
                                <option value="Pesquisador Colaborador">Pesquisador Colaborador</option>
                                <option value="Doutorado">Doutorado</option>
                                <option value="Mestrado">Mestrado</option>
                                <option value="Iniciação Científica">Iniciação Científica</option>
                                <option value="Técnico Químico">Técnico Químico</option>
                                <option value="Estagiário(a)">Estagiário(a)</option>
                            </FormSelect>
                        </FormLeft>
                        <FormLeft style={{width: "100%", height: '140px', justifyContent: 'center'}}>
                            <FormInput style={{marginTop: '25px'}} type="text" placeholder="Lattes" onChange={(e) => setLattes(e.target.value)} value={lattes}/>
                            <FormInput style={{marginTop: '5px'}}type="text" placeholder="Email" onChange={(e) => setDescription(e.target.value)} value={description}/>
                        </FormLeft>
                        {/* <FormTextArea style={{height: '218px'}} placeholder="Descrição" onChange={(e) => setDescription(e.target.value)} value={description}/>    */}
                    </FormInputs>
                    <ButtonsDiv>
                        <div style={{display: 'flex', flexDirection: 'row', width: '440px', justifyContent: 'space-between'}}>
                            <DeleteMemberButton onClick={() => handleDelete(memberId)}>Excluir</DeleteMemberButton>
                            <AddPhotoButton htmlFor="file">Escolher foto</AddPhotoButton>
                            <input style={{display:'none'}} type="file" name='arquivo' id="file" onChange={handleFile}/>
                        </div>
                        <AddMemberButton onClick={() => handleSubmit(memberId)}>Adicionar</AddMemberButton>
                    </ButtonsDiv>
                </Container>
            )}
            
            { teamMembers.filter(member => member.role === "Coordenador").length > 0 && (
            <MembersDiv>
                <MemberName style={{position: 'absolute', top:'0', left: '0'}} ><Trans i18nKey='coordenacao'></Trans></MemberName>
                {teamMembers.filter(member => member.role === "Coordenador").map((member) => (
                    <MemberItem>
                        <MemberPhoto src={member.photo}/>
                        <MemberInfo>
                            <MemberName>{member.name}</MemberName>
                            <MemberRole>{member.role}</MemberRole>
                            <MemberDescription href="mailto: {member.description}">{member.description}</MemberDescription>
                            <MemberLattes href={member.lattes}>Lattes</MemberLattes>
                        </MemberInfo>

                        {hasPermission && (
                                <EditButtonImg src={editButton} onClick={() => {
                                    setMemberId(member.id)
                                    setName(member.name)
                                    setRole(member.role)
                                    setDescription(member.description)
                                    setPhoto(member.photo)
                                    setLattes(member.lattes)
                                }} alt='edit'/>
                        )}
                    </MemberItem>
                ))}
            </MembersDiv>
        )}
  
        { teamMembers.filter(member => member.role === "Secretária").length > 0 && (
            <MembersDiv>
                <MemberName style={{position: 'absolute', top:'0', left: '0'}} ><Trans i18nKey='secretaria'></Trans></MemberName>
                {teamMembers.filter(member => member.role === "Secretária").map((member) => (
                    <MemberItem>
                   <MemberPhoto src={member.photo}/>
                   <MemberInfo>
                       <MemberName>{member.name}</MemberName>
                       <MemberRole>{member.role}</MemberRole>
                            <MemberDescription href="mailto: {member.description}">{member.description}</MemberDescription>
                       <MemberLattes href={member.lattes}>Lattes</MemberLattes>
                   </MemberInfo>

                   {hasPermission && (
                       <EditButtonImg src={editButton} onClick={() => {
                           setMemberId(member.id)
                           setName(member.name)
                           setRole(member.role)
                           setDescription(member.description)
                           setPhoto(member.photo)
                           setLattes(member.lattes)
                        }} alt='edit'/>
                        )}
               </MemberItem>
           ))}
       </MembersDiv>
        )}
  
        { teamMembers.filter(member => member.role === "Professor Associado").length > 0 && (
         <MembersDiv>
                <MemberName style={{position: 'absolute', top:'0', left: '0'}} ><Trans i18nKey='profassociados'></Trans></MemberName>
                { teamMembers.filter(member => member.role === "Professor Associado").length > 0 && teamMembers.filter(member => member.role === "Professor Associado").map((member) => (
                    <MemberItem>
                    <MemberPhoto src={member.photo}/>
                    <MemberInfo>
                        <MemberName>{member.name}</MemberName>
                        <MemberRole>{member.role}</MemberRole>
                            <MemberDescription href="mailto: {member.description}">{member.description}</MemberDescription>
                        <MemberLattes href={member.lattes}>Lattes</MemberLattes>
                    </MemberInfo>

                    {hasPermission && (
                            <EditButtonImg src={editButton} onClick={() => {
                                setMemberId(member.id)
                                setName(member.name)
                                setRole(member.role)
                                setDescription(member.description)
                                setPhoto(member.photo)
                                setLattes(member.lattes)
                            }} alt='edit'/>
                    )}
                </MemberItem>
            ))}
        </MembersDiv>
        )}
  
        { teamMembers.filter(member => member.role === "Professor Colaborador").length > 0 && (
        <MembersDiv>
                <MemberName style={{position: 'absolute', top:'0', left: '0'}} ><Trans i18nKey='profcolaboradores'></Trans></MemberName>
                {teamMembers.filter(member => member.role === "Professor Colaborador").map((member) => (
                    <MemberItem>
                    <MemberPhoto src={member.photo}/>
                    <MemberInfo>
                        <MemberName>{member.name}</MemberName>
                        <MemberRole>{member.role}</MemberRole>
                            <MemberDescription href="mailto: {member.description}">{member.description}</MemberDescription>
                        <MemberLattes href={member.lattes}>Lattes</MemberLattes>
                    </MemberInfo>

                    {hasPermission && (
                            <EditButtonImg src={editButton} onClick={() => {
                                setMemberId(member.id)
                                setName(member.name)
                                setRole(member.role)
                                setDescription(member.description)
                                setPhoto(member.photo)
                                setLattes(member.lattes)
                            }} alt='edit'/>
                    )}
                </MemberItem>
            ))}
        </MembersDiv>
        )}
  
        { teamMembers.filter(member => member.role === "Pesquisador Associado").length > 0 && (
        <MembersDiv>
               <MemberName style={{position: 'absolute', top:'0', left: '0'}} ><Trans i18nKey='pesqassociados'></Trans></MemberName>
                {teamMembers.filter(member => member.role === "Pesquisador Associado").map((member) => (
                    <MemberItem>
                    <MemberPhoto src={member.photo}/>
                    <MemberInfo>
                        <MemberName>{member.name}</MemberName>
                        <MemberRole>{member.role}</MemberRole>
                            <MemberDescription href="mailto: {member.description}">{member.description}</MemberDescription>
                        <MemberLattes href={member.lattes}>Lattes</MemberLattes>
                    </MemberInfo>

                    {hasPermission && (
                            <EditButtonImg src={editButton} onClick={() => {
                                setMemberId(member.id)
                                setName(member.name)
                                setRole(member.role)
                                setDescription(member.description)
                                setPhoto(member.photo)
                                setLattes(member.lattes)
                            }} alt='edit'/>
                    )}
                </MemberItem>
            ))}
        </MembersDiv>
        )}
  
        { teamMembers.filter(member => member.role === "Pesquisador Colaborador").length > 0 && (
        <MembersDiv>
                <MemberName style={{position: 'absolute', top:'0', left: '0'}} ><Trans i18nKey='pesqcolaboradores'></Trans></MemberName>
                {teamMembers.filter(member => member.role === "Pesquisador Colaborador").map((member) => (
                    <MemberItem>
                    <MemberPhoto src={member.photo}/>
                    <MemberInfo>
                        <MemberName>{member.name}</MemberName>
                        <MemberRole>{member.role}</MemberRole>
                            <MemberDescription href="mailto: {member.description}">{member.description}</MemberDescription>
                        <MemberLattes href={member.lattes}>Lattes</MemberLattes>
                    </MemberInfo>

                    {hasPermission && (
                            <EditButtonImg src={editButton} onClick={() => {
                                setMemberId(member.id)
                                setName(member.name)
                                setRole(member.role)
                                setDescription(member.description)
                                setPhoto(member.photo)
                                setLattes(member.lattes)
                            }} alt='edit'/>
                    )}
                </MemberItem>
            ))}
        </MembersDiv>
        )}
  
        { teamMembers.filter(member => member.role === "Doutorado").length > 0 && (
        <MembersDiv>
                <MemberName style={{position: 'absolute', top:'0', left: '0'}} ><Trans i18nKey='doutorandos'></Trans></MemberName>   
                {teamMembers.filter(member => member.role === "Doutorado").map((member) => (
                    <MemberItem>
                    <MemberPhoto src={member.photo}/>
                    <MemberInfo>
                        <MemberName>{member.name}</MemberName>
                        <MemberRole>{member.role}</MemberRole>
                            <MemberDescription href="mailto: {member.description}">{member.description}</MemberDescription>
                        <MemberLattes href={member.lattes}>Lattes</MemberLattes>
                    </MemberInfo>

                    {hasPermission && (
                            <EditButtonImg src={editButton} onClick={() => {
                                setMemberId(member.id)
                                setName(member.name)
                                setRole(member.role)
                                setDescription(member.description)
                                setPhoto(member.photo)
                                setLattes(member.lattes)
                            }} alt='edit'/>
                    )}
                </MemberItem>
            ))}
        </MembersDiv>
        )}
  
        { teamMembers.filter(member => member.role === "Mestrado").length > 0 && (
        <MembersDiv>
                <MemberName style={{position: 'absolute', top:'0', left: '0'}} ><Trans i18nKey='mestrandos'></Trans></MemberName>
                {teamMembers.filter(member => member.role === "Mestrado").map((member) => (
                    <MemberItem>
                    <MemberPhoto src={member.photo}/>
                    <MemberInfo>
                        <MemberName>{member.name}</MemberName>
                        <MemberRole>{member.role}</MemberRole>
                            <MemberDescription href="mailto: {member.description}">{member.description}</MemberDescription>
                        <MemberLattes href={member.lattes}>Lattes</MemberLattes>
                    </MemberInfo>

                    {hasPermission && (
                            <EditButtonImg src={editButton} onClick={() => {
                                setMemberId(member.id)
                                setName(member.name)
                                setRole(member.role)
                                setDescription(member.description)
                                setPhoto(member.photo)
                                setLattes(member.lattes)
                            }} alt='edit'/>
                    )}
                </MemberItem>
            ))}
        </MembersDiv>
        )}
  
        { teamMembers.filter(member => member.role === "Iniciação Científica").length > 0 && (
        <MembersDiv>
                <MemberName style={{position: 'absolute', top:'0', left: '0'}} ><Trans i18nKey='ic'></Trans></MemberName>
                {teamMembers.filter(member => member.role === "Iniciação Científica").map((member) => (
                    <MemberItem>
                    <MemberPhoto src={member.photo}/>
                    <MemberInfo>
                        <MemberName>{member.name}</MemberName>
                        <MemberRole>{member.role}</MemberRole>
                            <MemberDescription href="mailto: {member.description}">{member.description}</MemberDescription>
                        <MemberLattes href={member.lattes}>Lattes</MemberLattes>
                    </MemberInfo>

                    {hasPermission && (
                            <EditButtonImg src={editButton} onClick={() => {
                                setMemberId(member.id)
                                setName(member.name)
                                setRole(member.role)
                                setDescription(member.description)
                                setPhoto(member.photo)
                                setLattes(member.lattes)
                            }} alt='edit'/>
                    )}
                </MemberItem>
            ))}
        </MembersDiv>
        )}
  
        { teamMembers.filter(member => member.role === "Técnico Químico").length > 0 && (
            <MembersDiv>
                <MemberName style={{position: 'absolute', top:'0', left: '0'}} ><Trans i18nKey='tecnicos'></Trans></MemberName>
                {teamMembers.filter(member => member.role === "Técnico Químico").map((member) => (
                    <MemberItem>
                    <MemberPhoto src={member.photo}/>
                    <MemberInfo>
                        <MemberName>{member.name}</MemberName>
                        <MemberRole>{member.role}</MemberRole>
                            <MemberDescription href="mailto: {member.description}">{member.description}</MemberDescription>
                        <MemberLattes href={member.lattes}>Lattes</MemberLattes>
                    </MemberInfo>

                    {hasPermission && (
                            <EditButtonImg src={editButton} onClick={() => {
                                setMemberId(member.id)
                                setName(member.name)
                                setRole(member.role)
                                setDescription(member.description)
                                setPhoto(member.photo)
                                setLattes(member.lattes)
                            }} alt='edit'/>
                    )}
                </MemberItem>
            ))}
        </MembersDiv>
        )}
  

        { teamMembers.filter(member => member.role === "Estagiário(a)").length > 0 && (
                <MembersDiv>
                            <MemberName style={{position: 'absolute', top:'0', left: '0'}} ><Trans i18nKey='estagio'></Trans></MemberName>
                    
                    
                        { teamMembers.filter(member => member.role === "Estagiário(a)").map((member) => (
                            <MemberItem>
                            <MemberPhoto src={member.photo}/>
                            <MemberInfo>
                                <MemberName>{member.name}</MemberName>
                                <MemberRole>{member.role}</MemberRole>
                            <MemberDescription href="mailto: {member.description}">{member.description}</MemberDescription>
                                <MemberLattes href={member.lattes}>Lattes</MemberLattes>
                            </MemberInfo>

                            {hasPermission && (
                                    <EditButtonImg src={editButton} onClick={() => {
                                        setMemberId(member.id)
                                        setName(member.name)
                                        setRole(member.role)
                                        setDescription(member.description)
                                        setPhoto(member.photo)
                                        setLattes(member.lattes)
                                    }} alt='edit'/>
                            )}
                        </MemberItem>
                    ))}
                </MembersDiv>
        )}
  
        </div>
     );
}
 
export default Team;