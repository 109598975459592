import Home from './Home/Home';
import HomeSupporter from './Home/HomeSupporter';
import HomeCount from './Home/HomeCount';
import { useEffect } from 'react';

const Homepage = () => {
    useEffect(() => {
        document.title = "LARHCO - Instituto de Química da UFRJ";
    }, [])

    return ( 
        <div className="homepage">
            <Home/>
            <HomeSupporter/>
            <HomeCount/>
        </div>
     );
}
 
export default Homepage;