import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'pt',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
            labInstitute: "INSTITUTE OF CHEMISTRY - UFRJ",
            labName: 'Hydrocarbon Reactivity, Biomass and Catalysis Laboratory',
            labDescription: 'For over 25 years developing quality research and human resources in the field of chemistry',
            homeMoreButton: 'More',
            aboutCardTitle: 'About',
            aboutCardDesc: 'Know more about the laboratory',
            sciCardTitle: 'Scientific Production',
            sciCardDesc: 'Discovery our scientific production',
            blueCardTitle: 'Join our team',
            blueCardDescription: 'Be one of the researchers and students that are part of LARHCO',
            blueCardButton: 'Be part of our team',
            teamCardTitle: 'Team',
            teamCardDesc: 'Meet our researchers and students',
            projectCount: 'Research projects',
            articleCount: 'Published articles',
            teamCount: 'Teachers and students',
            equipsCount: 'Laboratory equipment',
            homeSupporterTitle: 'Sponsors and Partners',
            homeSupporterButton: 'Meet all partners',
            footerContactTitle: 'Contact',
            footerContactDesc: 'We are available for information and new projects',
            footerContactButton: 'Talk to us',
            footerAddress: 'Address',
            equipmentsTitle: 'Equipments',
            aboutLabTitle: 'About the laboratory',
            aboutLab: 'The Laboratory of Hydrocarbon Reactivity, Biomass and Catalysis (LARHCO) is located in the Shale Chemistry Hub of the Institute of Chemistry of UFRJ. Founded in 1997 and coordinated by Professor Claudio Jose de Araujo Mota, the group carries out research on heterogeneous catalysis and materials applied to energy and environment.',
            aboutLabBold1: 'Mission',
            aboutLab1: 'Train high-level human resources and carry out scientific-technological research in the areas of catalysis and materials applied to energy and the environment, contributing to the advancement of science, economic development and the well-being of the society.',
            aboutLabBold2: 'Vision',
            aboutLab2: 'To be a laboratory of excellence in research, recognized nationally and internationally, in the areas of energy and environment, disseminating knowledge and developing technologies to be used by the industrial sector.',
            aboutLabBold3: 'Values',
            aboutLab3A: 'Integration between all parties involved in the research',
            aboutLab3B: 'Efficiency and reliability of analyzes',
            aboutLab3C: 'Innovation and scientific and technological competence ',
            areasTitle: 'Research areas',
            aboutLabBold4: 'CO2 Capture and Conversion',
            aboutLab4A: 'CO2 is the great villain of the climate changes, being emitted upon the burning of fossil fuels. The capture and conversion of CO2 is an issue of great scientific and technological relevance.',
            aboutLab4B: 'In the area of CO2 capture, the group develops functionalized adsorbents for the capture of CO2 from gaseous streams, including the direct air capture (DAC). The studies are focused on materials based on biomass, which can perform equal to or better than conventional materials based on fossil sources.',
            aboutLab4C: 'LARHCO develops studies on CO2 hydrogenation to hydrocarbons, aiming at optimizing the formation of aviation fuel. In this line, supported metallic catalysts are used, which allow the reverse water gas shift (RWGS) and the Fischer-Tropsch synthesis (FTS) to be carried out in a single step. ',
            aboutLab4D: 'There are also studies on the hydrogenation of CO2 to methanol and dimethyl ether (DME). The group develops copper/zinc oxide catalysts, with different catalytic promoters, aiming at increasing activity and selectivity. In the production of dimethyl ether, the focus is on the development of bifunctional catalysts, based on copper/zinc oxide supported on acidic materials, to dehydrate the methanol formed to dimethyl ether.',
            aboutLab4E: 'Yet in the area of CO2 conversion, there are studies on the production of organic carbonates, which are chemicals with numerous industrial applications. The group develops heterogeneous catalysts based on metal oxides for the production of dimethyl carbonate. Zeolites and mesoporous oxides are studied as catalysts for the production of cyclic organic carbonates, such as propylene carbonate.',
            areasBold2: 'Production of Renewable Hydrogen ',
            areas2A: 'The hydrogen economy should dominate the second half of the 21st century, either through its direct use for energy generation or the industrial utilization in the production of renewable fuels and chemical products. ',
            areas2B: 'More recently, LARHCO began studies on the production of hydrogen via the photocatalytic and photoelectrocatalytic decomposition of water. The studies focus on the development of plasmonic photocatalysts that allow the use of visible light for the production of H2. ',
            areas2C: 'Another line of research involves the production of formic acid by the photocatalytic reduction of CO2 in aqueous solution. Formic acid has immense potential for use as a liquid hydrogen carrier. ',
            areasBold3: 'Biomass Conversion ',
            areas3A: 'The use of biomass for the production of fuels and chemicals is a topic of great interest today, given the need to reduce carbon emissions from fossil sources. ',
            areas3B: 'Studies in this line are currently centered on the conversion of ethanol, production and use of levulinates and the use of glycerol from biodiesel production. ',
            areas3C: 'In the area of ethanol conversion the studies are focused on the development of bifunctional catalysts for its direct conversion into ethylene oxide and ethylene glycol. This route would open up the possibility of obtaining several renewable polymers, including PET, at significantly lower costs. ',
            areas3D: 'In the area of levulinates, the studies aims at the production of ethyl levulinate directly from sugars using acid catalysts, as well as the use of inorganic levulinates as preservatives.',
            areas3E: 'Finally, studies with glycerol are currently focused on the development of derivatives that have applications as antioxidant and antifreeze additives, especially for the fuel sector. ',
            areasBold4: 'Fundaments of Catalysis ',
            areas4A: 'Studies in this area aim to develop innovative methods for studying the acidity and the mechanism of catalytic reactions on zeolites and other heterogeneous catalysts, in order to elucidate the catalytic processes.',
            areas4B: 'Current studies are mainly focused on theoretical calculations to understand the structure and energy of reaction intermediates and transition states of CO2 conversion reactions and biomass transformation. Methods of molecular mechanics and molecular dynamics are also used to simulate the CO2 capture on functionalized materials. The studies aim to correlate theoretical and experimental data to help in the development of better catalytic systems. ',
            qualityPoliceTitle: 'Quality Policy',
            qualityPoliceButton: 'Read our quality policy',
            qualityPoliceText1: 'The Quality Policy is aligned with the purpose of the organization, and consequently seeks to reflect the commitment of senior management and other LARHCO employees, aiming to comply with the legal requirements of the ISO 9001:2015 standard, as well as giving rise to the pursuit of continuous improvement and effectiveness of its Quality Management System (SGS).',
            qualityPoliceText2: 'Good professional practices, quality of services will be adopted and encourage provided, personnel training, care for safety and health at work, with environmental aspects, internal communication, customer feedback, critical analysis of service contracts, as well as the annual survey of scenarios internal and external to expand market share, reflecting on the strategic planning for defining the objectives and goals of the organization.',
            qualityPoliceText3: 'LARHCO has a committed team and mechanisms to guarantee permanent critical analysis of the management in relation to the Quality objectives.',   
            qualityPoliceText4: 'It also seeks to establish external partnerships with private companies, aiming to comply LARHCO objectives in relation to its QMS, Mission and Vision of the Future.',
            qualityPoliceText5: 'LARHCO is responsible for carrying out qualitative and quantitative analyzes using high-tech equipment and laboratory resources to maintain quality and reliability of the results delivered to its clients.',
            qualityPoliceText6: "LARHCO's mission is to train high-level human resources and carry out research scientific-technological research in the areas of catalysis applied to energy and the environment, contributing to the advancement of science, economic development and the well-being of the society.",
            qualityPoliceText7: 'Its vision is to be a laboratory of excellence in research, recognized nationally and internationally, in the areas of energy and environment, disseminating knowledge and developing technologies applicable across the industrial sector.',
            qualityPoliceText8: 'This policy reflects the commitment of all LARHCO, in an integrated manner, valuing the following aspects:',
            qualityPoliceTextA: 'a) The Management Policy is documented, implemented and maintained at all levels from LARHCO;',
            qualityPoliceTextB: 'b) LARHCO undertakes to offer services to its customers in accordance with with current norms, verifying their needs and carrying out their service paired with regulatory requirements;',
            qualityPoliceTextC: "c) Quality objectives, to meet ISO 9001:2015 standards, are defined annually, in a clear way, in the strategic planning of LARHCO for the purpose of continuous improvement of LARHCO's Quality Management System;",
            qualityPoliceTextD: 'd) LARHCO, through its training plans, programs and procedures, ensures that your quality management policy is in place and that all personnel involved in the activities are familiar with the System documentation Quality Management;',
            qualityPoliceTextE: 'e) LARHCO is committed to complying with the legislation, regulations in force and other requirements subscribed by it;',
            qualityPoliceTextF: 'f) LARHCO guarantees the dissemination of its quality management policy to all its staff or those working on their behalf to make them aware of their obligations personal issues related to Quality. This policy is available to interested parties and to the general public on the LARHCO board and on the LARHCO website (currently being updated).',
            qualityPoliceTextG: 'g) The quality management policy is reviewed annually at an appropriate meeting of critical analysis of coordination;',
            qualityPoliceTextH: 'h) Compliance with the LARHCO Quality Management System is ensured through internal and third-party audits;',
            qualityPoliceTextI: 'i) The LARHCO Coordinator ensures that the quality policy is in line with the purposes of the QMS based on NBR ISO 9001: 2015, committing to meet regulatory requirements and continually improve the effectiveness of the QMS, providing means for establishing objectives and critical analysis of Quality, which must be communicated and understood by all LARHCO, in order to receive contributions for its continuity and adequacy.',

            ebookButton: 'Read the e-book',

            coordenacao: 'Coordination',
            secretaria: 'Secretary',
            profassociados: 'Associated Professors',
            profcolaboradores: 'Collaborating Professors',
            pesqassociados: 'Associated Researchers',
            pesqcolaboradores: 'Collaborating Researchers',
            doutorandos: 'Doctoral Students',
            mestrandos: 'Master\'s Students',
            ic: 'Scientific Initiation',
            tecnicos: 'Chemical Technicians',
            estagio: 'Interns',

            projatuais: 'Actual projects',
            projant: 'Previous projects',
            tesesmestrado: 'MS dissertation',
            tesesdoutorado: 'PhD thesis',
            
            artigos: 'Published articles', 
            books: 'Books', 
            capitulos: 'Book chapters', 
            patent: 'Patents',
            
            extensao: 'Extension',
            extensaop1: '“University Extension, under the constitutional principle of inseparability between teaching, research and extension, is an interdisciplinary, educational, cultural, scientific and political process that promotes transformative interaction between the University and other sectors of society.” Forum of Deans of Extension of Brazilian Public Universities - FORPROEX, 2012',
            extensaop2: 'Check out our extension action: ',
            extensaop3: 'Read our e-book in partnership with the Brazilian Society of Chemistry (SBQ):',
            extensaop4: 'Watch our lives below:',

            finatuais: 'Ongoing projects',
            finanteriores: 'Finished projects',
            parcerias: 'Partnerships and collaborations',

            headerHome: 'Home',
            headerAbout: 'Laboratory',
            headerTeam: 'Team',
            headerProjects: 'Projects',
            headerArticles: 'Articles',
            headerExtensions: 'Extensions',
            headerSupporters: 'Sponsors and Partners',
            headerContact: 'Contact',
            
            contato: 'Contact',
            contatop1: 'We are available for information and new projects. Talk to us using the form below, we will respond as soon as possible.',
            enviar: 'Send',
            local: 'Location',

            placeTitle: 'Places',
          }
        },
        pt: {
          translation: {
            labInstitute: "INSTITUTO DE QUÍMICA - UFRJ",
            labName: 'Laboratório de Reatividade de Hidrocarbonetos, Biomassa e Catálise',
            labDescription: 'Há mais de 20 anos desenvolvendo pesquisa e recursos humanos de qualidade na área de química',
            homeMoreButton: 'Conheça',
            aboutCardTitle: 'Sobre',
            aboutCardDesc: 'Saiba mais sobre o laboratório',
            sciCardTitle: 'Produção Científica',
            sciCardDesc: 'Veja nossas produções científicas',
            blueCardTitle: 'Seja do LARHCO',
            blueCardDescription: 'Seja um dos psquisadores e alunos que fazem parte do LARHCO',
            blueCardButton: 'Faça parte da nossa equipe',
            teamCardTitle: 'Equipe',
            teamCardDesc: 'Conheça nossos pesquisadores e alunos',
            projectCount: 'Projetos de pesquisa',
            articleCount: 'Artigos publicados',
            teamCount: 'Professores e alunos',
            equipsCount: 'Equipamentos laboratoriais',
            homeSupporterTitle: 'Financiadores e Parceiros',
            homeSupporterButton: 'Conheça todos os parceiros',
            footerContactTitle: 'Contato',
            footerContactDesc: 'Estamos a disposição para informações e novos projetos',
            footerContactButton: 'Fale com a gente',
            footerAddress: 'Endereço',
            equipmentsTitle: 'Equipamentos',
            aboutLabTitle: 'Sobre o laboratório',
            aboutLab: 'O Laboratório de Reatividade de Hidrocarbonetos, Biomassa e Catálise (LARHCO) está localizado no Polo de Xistoquímica do Instituto de Química da UFRJ. Fundado em 1997 e coordenado pelo professor Claudio Jose de Araujo Mota, apresenta como principal área de atuação a catálise heterogênea e o desenvolvimento de matérias para aplicações em energia e ambiente.',
            aboutLabBold1: 'Missão',
            aboutLab1: 'Formar recursos humanos de alto nível e realizar pesquisa científico-tecnológica nas áreas de catálise e materiais aplicados à energia e ao meio ambiente, contribuindo para o avanço da ciência, o desenvolvimento econômico e o bem-estar da sociedade.',
            aboutLabBold2: 'Visão',
            aboutLab2: 'Ser um laboratório de excelência em pesquisa, reconhecido nacional e internacionalmente, nas áreas de energia e meio ambiente, disseminando conhecimento e desenvolvendo tecnologias para aproveitamento pelo setor industrial.',
            aboutLabBold3: 'Valores',
            aboutLab3A: 'Integração entre todas as partes envolvidas na pesquisa',
            aboutLab3B: 'Eficiência e confiabilidade das análises',
            aboutLab3C: 'Inovação e competência científica e tecnológica',
            areasTitle: 'Linhas de pesquisa',
            aboutLabBold4: 'Captura e Conversão de CO2',
            aboutLab4A: 'O CO2 é o grande vilão das mudanças climáticas, sendo emitido na queima de combustíveis fósseis. A captura e conversão do CO2 é um assunto atual e de grande relevância científico-tecnológica.',
            aboutLab4B: 'Na linha de captura de CO2 o grupo desenvolve adsorventes funcionalizados para a captura de CO2 de correntes gasosas, incluindo a captura direta do ar atmosférico. Os estudos são focados em materiais baseados em biomassa, que possam apresentar desempenho igual ou superior aos materiais convencionais, baseados em fontes fósseis.',
            aboutLab4C: 'O LARHCO estuda a hidrogenação do CO2 a hidrocarbonetos, sobretudo otimizando a formação de combustível de aviação. Nesta linha são utilizados catalisadores metálicos suportados, que permitem realizar a reação reversa de gás d’água (RWGS) e a síntese de Fischer-Tropsch (FTS) em uma única etapa.',
            aboutLab4D: 'Há também estudos de hidrogenação do CO2 a metanol e dimetil-éter (DME). O grupo desenvolve catalisadores de cobre e óxido de zinco, com diferentes promotores catalíticos, visando aumentar a atividade e seletividade. Já na produção de dimetil-éter o foco é desenvolver catalisadores bifuncionais, baseados em cobre e óxido de zinco suportados em materiais ácidos, para desidratar o metanol formado a dimetil-éter.',
            aboutLab4E: 'Ainda em conversão de CO2 há estudos sobre produção de carbonatos orgânicos, produtos com inúmeras aplicações industriais. O grupo desenvolve catalisadores heterogêneos baseados em óxidos metálicos para a produção de carbonato de dimetila. Zeólitas e óxidos mesoporosos são estudados como catalisadores para a produção de carbonatos orgânicos cíclicos, como o carbonato de propileno.',
            areasBold2: 'Produção de Hidrogênio Renovável',
            areas2A: 'A economia do hidrogênio deve dominar a segunda metade do século XXI, quer seja pela utilização energética direta, quer para o emprego industrial na produção de combustíveis renováveis e produtos químicos. ',
            areas2B: 'O LARHCO iniciou, mais recentemente, estudos sobre a produção de hidrogênio via decomposição fotocatalítica e fotoeletrocatalítica da água. Os estudos focam o desenvolvimento de fotocatalisadores plasmônicos que permitem o uso de luz visível para a produção de H2. ',
            areas2C: 'Outra linha de pesquisa envolve a produção de ácido fórmico pela reação fotocatalítica do CO2 com a água. O ácido fórmico tem imenso potencial de uso como transportador líquido de hidrogênio.',
            areasBold3: 'Conversão de Biomassa ',
            areas3A: 'A utilização da biomassa para produção de combustíveis e produtos químicos é um tópico de grande interesse na atualidade, haja vista a necessidade de diminuição das emissões de carbono de origem fósseis. ',
            areas3B: 'Os estudos nesta linha estão centrados, atualmente, na conversão de etanol, produção e utilização de levulinatos e no aproveitamento do glicerol de produção de biodiesel. ',
            areas3C: 'Na linha de conversão de etanol os estudos são focados no desenvolvimento de catalisadores bifuncionais para a conversão direta de etanol em óxido de etileno e etileno glicol. Essa rota abriria a possibilidade de obtenção de diversos polímeros renováveis, entre eles o PET, a custos significativamente menores. ',
            areas3D: 'Na linha de levulinatos os estudos focam na produção de levulinato de etila diretamente a partir de açúcares, utilizando catalisadores ácidos, bem como no emprego de levulinatos inorgânicos como conservantes. ',
            areas3E: 'Por fim, os estudos com glicerol estão focados, atualmente, no desenvolvimento de derivados que possuem aplicações como aditivos antioxidantes e anticongelantes, sobretudo para o setor de combustíveis. ',
            areasBold4: 'Fundamentos de Catálise ',
            areas4A: 'Os estudos nesta linha visam desenvolver métodos inovadores para estudo da acidez e mecanismo de reações catalíticas sobre zeólitas e outros catalisadores heterogêneos, de forma a elucidar seu papel em processos catalíticos. ',
            areas4B: 'Os estudos atuais estão focados sobretudo em cálculos teóricos para entender a estrutura e energia de intermediários reacionais e estados de transição de reações de conversão de CO2 e transformação de biomassa. Também são usados métodos de mecânica e dinâmica molecular para simular processos de captura de CO2 sobre materiais funcionalizados. Os estudos visam correlacionar dados teóricos e experimentais para ajudar no desenvolvimento de melhores sistemas catalíticos. ',
            qualityPoliceTitle: 'Política de Qualidade',
            qualityPoliceButton: 'Leia nossa política de qualidade',
            qualityPoliceText1: 'A Política da Qualidade está alinhada ao propósito da organização, e consequentemente busca refletir o comprometimento da alta direção e demais colaboradores do LARHCO, visando cumprir os requisitos legais da norma ISO 9001:2015, bem como ensejar a busca da melhoria contínua e da eficácia de seu Sistema de Gestão da Qualidade.',
            qualityPoliceText2: 'Serão adotadas e incentivadas as boas práticas profissionais, a qualidade dos serviços prestados, a capacitação de pessoal, os cuidados com a segurança e saúde no trabalho, com os aspectos ambientais, a comunicação interna, a realimentação de clientes, a análise crítica dos contratos de serviços, bem como, o levantamento anual dos cenários interno e externo para ampliação da participação no mercado, refletindo no planejamento estratégico para a definição dos objetivos e metas da organização.',
            qualityPoliceText3: 'Os resultados obtidos serão divulgados e discutidos com os colaboradores, visando a melhoria contínua do Sistema de Gestão da Qualidade.',   
            qualityPoliceText4: 'Também busca estabelecer parcerias externas com empresas privadas, visando cumprir os objetivos do LARHCO em relação ao seu SGQ, Missão e sua Visão de Futuro.',
            qualityPoliceText5: 'O LARHCO é responsável por realizar análises qualitativas e quantitativas utilizando equipamentos de alta tecnologia e recursos laboratoriais para manter a qualidade e confiabilidade dos resultados entregues aos seus clientes.',
            qualityPoliceText6: 'A missão do LARHCO é formar recursos humanos de alto nível e realizar pesquisa científico-tecnológica nas áreas de catálise aplicada à energia e meio ambiente, contribuindo para o avanço da ciência, desenvolvimento econômico e bem estar da sociedade.',
            qualityPoliceText7: 'Sua visão é ser um laboratório de excelência em pesquisa, reconhecido nacional e internacionalmente, nas áreas de energia e meio ambiente, disseminando conhecimento e desenvolvendo tecnologias aplicáveis pelo setor industrial.',
            qualityPoliceText8: 'Esta política reflete o comprometimento de todo LARHCO, de forma integrada, valorizando os seguintes aspectos:',
            qualityPoliceTextA: 'a) A Política de Gestão está documentada, implementada e mantida em todos os níveis do LARHCO;',
            qualityPoliceTextB: 'b) O LARHCO se compromete a oferecer serviços aos seus clientes em conformidade com as normatizações vigentes verificando as suas necessidades e realizando seu atendimento emparelhado com os requisitos normativos;',
            qualityPoliceTextC: 'c) Os objetivos da Qualidade, para atender as normas ISO 9001:2015, são definidos anualmente, de forma clara, no planejamento estratégico do LARHCO para efeito da melhoria contínua do Sistema de Gestão da Qualidade do LARHCO;',
            qualityPoliceTextD: 'd) O LARHCO, através de seus planos, programas e procedimentos de treinamento, garante que a sua política de gestão da qualidade esteja implementada e que todo o pessoal envolvido nas atividades esteja familiarizado com a documentação do Sistema de Gestão da Qualidade;',
            qualityPoliceTextE: 'e) O LARHCO tem compromisso de cumprir a legislação, regulamentos em vigor e outros requisitos subscritos pelo mesmo;',
            qualityPoliceTextF: 'f) O LARHCO garante a divulgação da sua política de gestão da qualidade para toda sua equipe ou àqueles que trabalhem em seu nome para conscientizá-los de suas obrigações pessoais relacionadas à Qualidade. Esta política está disponível às partes interessadas e ao público em geral no quadro do LARHCO e no site do LARHCO (em atualização).',
            qualityPoliceTextG: 'g) A política de gestão da qualidade é revista anualmente em reunião apropriada de análise crítica da coordenação;',
            qualityPoliceTextH: 'h) A conformidade do Sistema de Gestão da Qualidade do LARHCO é assegurada através das auditorias internas e de terceira parte;',
            qualityPoliceTextI: 'i) O Coordenador do LARHCO assegura que a política da qualidade está em consonância com os propósitos do SGQ com base na NBR ISO 9001:2015, comprometendo-se em atender aos requisitos normativos e melhorar continuamente a eficácia do SGQ, provendo meios para o estabelecimento dos objetivos e análise crítica da Qualidade, a qual deverá ser comunicada e entendida por todo LARHCO, de forma a receber contribuições para sua continuidade e adequação.',
        
            ebookButton: 'Leia o e-book',

            coordenacao: 'Coordenação',
            secretaria: 'Secretaria',
            profassociados: 'Professores Associados',
            profcolaboradores: 'Professores Colaboradores',
            pesqassociados: 'Pesquisadores Associados',
            pesqcolaboradores: 'Pesquisadores Colaboradores',
            doutorandos: 'Doutorandos',
            mestrandos: 'Mestrandos',
            ic: 'Iniciação Científica',
            tecnicos: 'Técnicos Químicos',
            estagio: 'Estagiários',

            projatuais: 'Projetos atuais',
            projant: 'Projetos anteriores',
            tesesmestrado: 'Dissertações de mestrado',
            tesesdoutorado: 'Teses de doutorado',

            artigos: 'Artigos publicados',
            books: 'Livros',
            capitulos: 'Capítulos de livros',
            pantent: 'Patentes',

            extensao: 'Extensão',
            extensaop1: '“A Extensão Universitária, sob o princípio constitucional da indissociabilidade entre ensino, pesquisa e extensão, é um processo interdisciplinar, educativo, cultural, científico e político que promove a interação transformadora entre Universidade e outros setores da sociedade.”   Fórum de Pró-Reitores de Extensão das Universidades Públicas Brasileiras - FORPROEX, 2012',
            extensaop2: 'Conheça nossa ação de extensão: ',
            extensaop3: 'Leia nosso e-book em parceria com a Sociedade Brasileira de Química (SBQ):',
            extensaop4: 'Assista nossas lives abaixo:',

            finatuais: 'Projetos em andamento',
            finanteriores: 'Projetos finalizados',
            parcerias: 'Parcerias e colaborações',

            headerHome: "Início",
            headerAbout: 'Laboratório',
            headerTeam: 'Equipe',
            headerProjects: 'Projetos',
            headerArticles: 'Publicações',
            headerContact: 'Contato',
            headerExtensions: 'Extensão',

            contato: 'Contato',
            contatop1: 'Estamos a disposição para informações e novos projetos. Fale com a gente através do formulário abaixo, responderemos assim que possível.',
            enviar: 'Enviar',
            local: 'Localização',

            placeTitle: 'Ambientes',
            headerSupporters: 'Financiadores e Parceiros'
          }
      }
    },
  });

export default i18n;