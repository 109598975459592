import {Container, ContainerTitle, ContainerDescription, hasPermissionContainer, ContainerBlueTitle, ContainerItem, FormButton, FormLeft, FormInput, FormInputs, FormTextArea, ButtonsDiv, DeleteButton} from '../styles'
import styled from "styled-components";
import { Trans } from 'react-i18next';

import { useState, useEffect } from "react";

const Project = () => {

    const [account, setAccount] = useState({});

    const [hasPermission, setHasPermission] = useState(false);

    const [projects, setProjects] = useState([]);

    const [currentProjects, setCurrentProjects] = useState([]);
    const [pastProjects, setPastProjects] = useState([]);
    const [docTeses, setDocTeses] = useState([]);
    const [mestTeses, setMestTeses] = useState([]);

    useEffect(() => {
        document.title = "Projetos - LARHCO - Instituto de Química da UFRJ";

        const localStorageAccount = localStorage.getItem("account")
        const localStoragePassword = localStorage.getItem("password")

        if (localStorageAccount === "admin" && localStoragePassword === "admin") {
            setAccount({
                account: localStorageAccount,
                password: localStoragePassword
            })

            console.log(account)
setHasPermission(true)

        } else {
            setAccount({
                account: "",
                password: ""
            })

            setHasPermission(false)
        }
        
        fetch("https://api-larhco.vercel.app/api/projects")
            .then(res => res.json())
            .then(data => {
                setProjects(data)
                const currentProjects = data.filter(project => project.category === "atual")
                const pastProjects = data.filter(project => project.category === "anterior")
                const docTeses = data.filter(project => project.category === "docteses")
                const mestTeses = data.filter(project => project.category === "mestteses")
                setDocTeses(docTeses.reverse())
                setMestTeses(mestTeses.reverse())
                setCurrentProjects(currentProjects.reverse())
                setPastProjects(pastProjects.reverse())
            })
            .then(() => {
                console.log(currentProjects)
                console.log(pastProjects)
            })
    }, [])

    const [projectID, setProjectID] = useState(0);
    const [projectName, setProjectName] = useState("");
    const [projectDescription, setProjectDescription] = useState("");
    const [projectLink, setProjectLink] = useState("");
    const [projectType, setProjectType] = useState("atual");
    
    const addProject = (id) => {

        if (id !== 0){
            console.log('é pra editar', id)
            return editProject(id);
        }

        const project = {
            name: projectName,
            description: projectDescription,
            url: projectLink,
            category: projectType
        }
        console.log(project)
        fetch("https://api-larhco.vercel.app/api/projects", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
                },
                body: JSON.stringify(project)
            })
            .then(response => response.json())
            .then(setTimeout(() => window.location.reload(), 1500))
    }

    const deleteProject = (id) => {
        fetch(`https://api-larhco.vercel.app/api/projects/${id}`, {
            method: "DELETE"
        })
        .then(response => response.json())
        .then(setTimeout(() => window.location.reload(), 1500))
    }

    const editProject = (id) => {
        const project = {
            name: projectName,
            description: projectDescription,
            url: projectLink,
            category: projectType
        }
        console.log(project, id)
        // fetch(`https://api-larhco.vercel.app/api/projects/${id}`, {
        //     method: "PUT",
        //     headers: {
        //         "Content-Type": "application/json"
        //         },
        //         body: JSON.stringify(project)
        //     })
        //     .then(response => response.json())
        //     .then(data => console.log(data))

        fetch(`https://api-larhco.vercel.app/api/projects/${id}`, {
            method: "DELETE"
        })
        .then(response => response.json())
        .then(fetch("https://api-larhco.vercel.app/api/projects", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
                },
                body: JSON.stringify(project)
                })
                .then(response => response.json())
                .then(setTimeout(() => window.location.reload(), 1500)))
    }

    const FormSelect = styled.select`
        background: #FFFFFF;
        border: 1px solid #514F6E;
        border-radius: 14px;
        width: 430px;
        height: 58px;
        // margin: 11px;
        padding: 18px;
        &:focus {
            outline: none;
            border: 1px solid #0091DB;
            box-shadow: 0px 0px 4px #0091DB;
        }
        @media (min-width: 768px) and (max-width: 1100px){
            width: 200px;
        }
        @media (max-width: 767px){
            width: 100%;
            // margin: 11px 0;
        }
    `

    const [deleteButtonVisibility, setDeleteButtonVisibility] = useState('none');

    useEffect(() => {
        if (projectID !== 0) {
            setDeleteButtonVisibility('flex')
        } else {
            setDeleteButtonVisibility('none')
        }
    }, [projectID])

    const DeleteProjectButton = styled(DeleteButton)`
        display: ${deleteButtonVisibility};
    `

    return ( 
        <div className='main-div'>

            {hasPermission && (
                <Container>
                    <ContainerTitle>Novo projeto</ContainerTitle>
                    <FormInputs>
                        <FormLeft>
                            <FormInput type="text" placeholder="Título" onChange={(e) => setProjectName(e.target.value)} value={projectName}/>
                            <FormInput type="text" placeholder="Link" onChange={(e) => setProjectLink(e.target.value)} value={projectLink}/>
                            <FormSelect onChange={(e) => setProjectType(e.target.value)} value={projectType}>
                                <option value="atual" >Atual</option>
                                <option value="anterior" >Anterior</option>
                                <option value="docteses" >Tese de doutorado</option>
                                <option value="mestteses" >Tese de mestrado</option>
                            </FormSelect>
                        </FormLeft>
                        <FormTextArea style={{height: '218px'}} placeholder="Descrição" onChange={(e) => setProjectDescription(e.target.value)} value={projectDescription}/>   
                    </FormInputs>
                    <ButtonsDiv>
                        <DeleteProjectButton onClick={() => deleteProject(projectID)}>Excluir</DeleteProjectButton>
                        <FormButton onClick={() => addProject(projectID)}>Adicionar</FormButton>
                    </ButtonsDiv>
                </Container>
            )}
            
            <Container>
                <ContainerTitle><Trans i18nKey='projatuais'></Trans></ContainerTitle>
                {
                    currentProjects.map((project) => (
                        <ContainerItem onClick={
                            () => {
                                setProjectName(project.name)
                                setProjectDescription(project.description)
                                setProjectLink(project.url)
                                setProjectType(project.category)
                                setProjectID(project.id)
                            }
                        }>
                            <ContainerBlueTitle>{project.name}</ContainerBlueTitle>
                            <ContainerDescription>{project.description}</ContainerDescription>
                        </ContainerItem>
                    ))
                }

            </Container>
            <Container>
                <ContainerTitle><Trans i18nKey='projant'></Trans></ContainerTitle>
                {
                    pastProjects.map((project) => (
                        <ContainerItem onClick={
                            () => {
                                setProjectName(project.name)
                                setProjectDescription(project.description)
                                setProjectLink(project.url)
                                setProjectType(project.category)
                                setProjectID(project.id)
                            }
                        }>
                            <ContainerBlueTitle>{project.name}</ContainerBlueTitle>
                            <ContainerDescription>{project.description}</ContainerDescription>
                        </ContainerItem>
                    ))
                }
            </Container>

            <Container>
                <ContainerTitle><Trans i18nKey='tesesmestrado'></Trans></ContainerTitle>
                {
                   mestTeses.map((project) => (
                        <ContainerItem onClick={
                            () => {
                                setProjectName(project.name)
                                setProjectDescription(project.description)
                                setProjectLink(project.url)
                                setProjectType(project.category)
                                setProjectID(project.id)
                            }
                        }>
                            <ContainerBlueTitle>{project.name}</ContainerBlueTitle>
                            <ContainerDescription>{project.description}</ContainerDescription>
                        </ContainerItem>
                    ))
                }

            </Container>

            <Container>
                <ContainerTitle><Trans i18nKey='tesesdoutorado'></Trans></ContainerTitle>
                {
                    docTeses.map((project) => (
                        <ContainerItem onClick={
                            () => {
                                setProjectName(project.name)
                                setProjectDescription(project.description)
                                setProjectLink(project.url)
                                setProjectType(project.category)
                                setProjectID(project.id)
                            }
                        }>
                            <ContainerBlueTitle>{project.name}</ContainerBlueTitle>
                            <ContainerDescription>{project.description}</ContainerDescription>
                        </ContainerItem>
                    ))
                }
            </Container>
        </div>
        );
}


 
export default Project;