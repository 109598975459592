import { Trans } from 'react-i18next';
import { useState, useEffect } from 'react';
import { Container, ContainerTitle, ContainerDescription, FormButton, FormInput, FormInputs, ButtonsDiv, DeleteButton } from "../styles";
import styled from 'styled-components';

const Extensions = () => {

    const [account, setAccount] = useState({});
    const [hasPermission, setHasPermission] = useState(false);
    const [extensions, setExtensions] = useState([]);

    useEffect(() => {
        document.title = "Extensão - LARHCO - Instituto de Química da UFRJ";

        const localStorageAccount = localStorage.getItem("account")
        const localStoragePassword = localStorage.getItem("password")

        if (localStorageAccount === "admin" && localStoragePassword === "admin") {
            setAccount({
                account: localStorageAccount,
                password: localStoragePassword
            })

            console.log(account)
setHasPermission(true)

        } else {
            setAccount({
                account: "",
                password: ""
            })

            setHasPermission(false)
        }
        
        fetch("https://api-larhco.vercel.app/api/extensions")
            .then(res => res.json())
            .then(data => {
                setExtensions(data)
            })
    }, [])


    const [id, setId] = useState(0);
    const [name, setName] = useState("");
    const [url, seturl] = useState("");
    const [photo, setPhoto] = useState("");

    const handleFile = async () => {

        const file = document.getElementById('file').files[0];

        let body = new FormData()
        body.set('key', '8bfbdf15eed781a8475a520584a4b18c')
        body.append('image', file)
    
        return fetch('https://api.imgbb.com/1/upload', {
            method: 'POST',
            body
        })
        .then(response => response.json())
        .then(result => {
            console.log(result.data.url)
            setPhoto(result.data.url)
        }
        )

      }

    const [buttonVisibility, setButtonVisibility] = useState("none")

    useEffect (() => {
        if (photo !== "") {
            setButtonVisibility("auto")
        }
    }, [photo])

    const handleSubmit = (id) => {
        if (id !== 0){
            console.log('é pra editar', id)
            return handleEdit(id);
        }

        const extension = {
            name: name,
            url: url,
            photo: photo
        }

        console.log(extension)
        
        fetch("https://api-larhco.vercel.app/api/extensions", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(extension)
        })
        .then(res => res.json())
        .then(response => response.json())
        .then(setTimeout(() => window.location.reload(), 1500))
    }

    const handleDelete = (id) => {
        fetch(`https://api-larhco.vercel.app/api/extensions/${id}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(setTimeout(() => window.location.reload(), 1500))
    }

    const handleEdit = (id) => {
        const extension = {
            name: name,
            url: url,
            photo: photo
        }

        console.log(extension)

        fetch(`https://api-larhco.vercel.app/api/extensions/${id}`, {
            method: "DELETE"
        })
        .then(response => response.json())
        .then(fetch("https://api-larhco.vercel.app/api/extensions", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(extension)
            })
            .then(response => response.json())
            .then(setTimeout(() => window.location.reload(), 1500)))
    }

    const ExtensionsDiv = styled.div`
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        gap: 50px;
        align-items: flex-start;
        align-content: center;
        width: 1000px;
        margin: 0 auto 40px;
        @media (min-width: 768px) and (max-width: 1100px){
            width: 660px;
        }
        @media (max-width: 767px){
            width: 100%;
            justify-content: center;
        }
    `

    const ExtensionItem = styled.div`
        width: 300px;
        border-radius: 18px;
        position: relative;
        height: 400px;
        transition: all 0.5s;
        &:hover {
            transform: scale(1.06);
        }
    `
        
    const ExtensionPhoto = styled.img`
        filter: drop-shadow(0px 5px 14px rgba(8, 15, 52, 0.25));
        border-radius: 18px;
        width: 100%;
        cursor: pointer;
        position: absolute;
        bottom: 0;
    `

    const AddPhotoButton = styled.label`
        width: 200px;
        height: 64px;
        background: linear-gradient(103.39deg, #0058DB 23.75%, #009FE3 100.26%);
        box-shadow: 0px 3px 12px rgba(74, 58, 255, 0.18);
        border-radius: 56px;    
        font-weight: 400;
        font-size: 18px;
        // line-height: 20px;
        text-align: center;
        margin-top: 20px;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    `

    const AddExtensionButton = styled(FormButton)`
        width: 200px;
        display: ${buttonVisibility};
    `

    const [deleteButtonVisibility, setDeleteButtonVisibility] = useState('none');

    useEffect(() => {
        if (id !== 0) {
            setDeleteButtonVisibility('flex')
        } else {
            setDeleteButtonVisibility('none')
        }
    }, [id])

    const DeleteExtensionButton = styled(DeleteButton)`
        width: 200px;
        display: ${deleteButtonVisibility};
    `

    const MiniPToEdit = styled.p`
        font-size: 14px;
        // line-height: 16px;
        color: #000000;
        margin: 0;
        margin-bottom: 10px;
        cursor: pointer;
    `

    const PDFButton = styled.a`
        width: auto;
        padding: 0 40px;
        height: 65px;
        text-decoration: none;
        background: linear-gradient(103.39deg, #0058DB 23.75%, #009FE3 100.26%);
        box-shadow: 0px 3px 12px rgba(74, 58, 255, 0.18);
        border-radius: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        font-size: 18px;
      // line-height: 20px;
        text-align: center;
        color: #FFFFFF;
        margin: auto;
        cursor: pointer;
        transition: all 0.5s;
        &:hover {
            transform: scale(1.06);
        }
        @media (max-width: 767px){
            display: none;
        }
    `

    return ( 
        <div className='main-div'>

            {hasPermission && (
                <Container>
                    <ContainerTitle>Nova extensão</ContainerTitle>
                    <FormInputs>
                            <FormInput type="text" placeholder="Título" onChange={(e) => setName(e.target.value)} value={name}/>
                            <FormInput type="text" placeholder="Link" onChange={(e) => seturl(e.target.value)} value={url}/>
                    </FormInputs>
                    <ButtonsDiv>
                        <div style={{display: 'flex', flexDirection: 'row', width: '440px', justifyContent: 'space-between'}}>
                            <DeleteExtensionButton onClick={() => handleDelete(id)}>Excluir extensão</DeleteExtensionButton>
                            <AddPhotoButton htmlFor="file">Escolher foto</AddPhotoButton>
                            <input style={{display:'none'}} type="file" name='arquivo' id="file" onChange={handleFile}/>
                        </div>
                        <AddExtensionButton onClick={() => handleSubmit(id)}>Adicionar extensão</AddExtensionButton>
                    </ButtonsDiv>
                </Container>
            )}

            <Container>
                <ContainerTitle><Trans i18nKey='extensao'></Trans></ContainerTitle>
                <ContainerDescription><Trans i18nKey='extensaop1'></Trans></ContainerDescription>
                <ContainerDescription><Trans i18nKey='extensaop2'></Trans><a style={{ textDecoration: 'none', color: '#0091DB' }} href="https://instagram.com/ufrjsustentabilidade">Química e Sustentabilidade</a></ContainerDescription>
                <ContainerDescription><Trans i18nKey='extensaop3'></Trans>Leia nosso e-book em parceria com a Sociedade Brasileira de Química (SBQ):</ContainerDescription>
                <PDFButton href="http://quid.sbq.org.br/wp-content/uploads/2022/11/residuos-quid-sbq-2022.pdf" style={{margin: '30px auto'}}><Trans i18nKey='ebookButton'>Leia nossa política de qualidade</Trans></PDFButton>
                <ContainerDescription><b><Trans i18nKey='extensaop4'></Trans>Assista nossas lives abaixo:</b></ContainerDescription>
            </Container>
            
            <ExtensionsDiv>
                {extensions.map((extension) => (
                    <ExtensionItem>
                        {hasPermission && (
                                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around', width: '100%'}}>
                                    <MiniPToEdit style={{color: '#0091DB'}} onClick={() => {
                                        setId(extension.id)
                                        setName(extension.name)
                                        seturl(extension.url)
                                        setPhoto(extension.photo)
                                    }}>Editar</MiniPToEdit>
                                    <MiniPToEdit style={{color: 'red'}} onClick={() => handleDelete(extension.id)}>Excluir</MiniPToEdit>
                                </div>
                            )}
                        <ExtensionPhoto src={extension.photo} onClick={() => {window.open(extension.url, "_blank")}}/>
                    </ExtensionItem>
                ))}

            </ExtensionsDiv>
        </div>
     );
}
 
export default Extensions;