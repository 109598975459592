import styled from 'styled-components';

import downArrow from '../../assets/down-arrow.svg';
import paper from '../../assets/paper.svg';
import group from '../../assets/group.svg';
import id from '../../assets/id.svg';
import labImage from '../../assets/lab-image.png';

import leftCircle from '../../assets/left-circle.png';
import rightCircle from '../../assets/right-circle.png';

import Fade from 'react-reveal/Fade';
import { Trans } from 'react-i18next';

const Home = () => {

    const Home = styled.div`
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: #ECF0FF;
    `

    const HomeInfo = styled.div`
        width: 1000px;
        height: calc(100vh - 130px);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        z-index: 2;
        @media (min-width: 768px) and (max-width: 1100px){
            width: 600px;
        }
    `

    const HomeSpans = styled.div`
        margin-top: -100px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        
    `

    const InstitutoName = styled.h1`
        font-size: 14px;
        color: #0091DB;
        weight: 700;
        letter-spacing: 0.24em;
        text-transform: uppercase;
        @media (min-width: 768px) and (max-width: 1100px){
            font-size: 14px;
        }
        @media (max-width: 767px){
            font-size: 13px;
        }
    `

    const LaboratorioName = styled.h1`
        margin-top: 20px;
        font-size: 40px;
        weight: 700;
        color: #1e1e1e;
        text-align: center;
        @media (min-width: 768px) and (max-width: 1100px){
            font-size: 34px;
        }
        @media (max-width: 767px){
            font-size: 20px;
            width: 350px;
        }
    `

    const LaboratorioDescription = styled.p`
        margin-top: 20px;
        font-size: 18px;
        color: #6f6c90;
        text-align: center;
        font-weight: 400;
        width: 650px;
        @media (min-width: 768px) and (max-width: 1100px){
            font-size: 16px;
        }
        @media (max-width: 767px){
            font-size: 13px;
            width: 290px;
        }
    `

    const HomeButton = styled.div`
        margin-top: 20px;
        width: 155px;
        display: inline-block;
        height: 53px !important;
        background: linear-gradient(103.39deg, #0058DB 23.75%, #009FE3 100.26%);
        box-shadow: 0px 3px 12px rgba(74, 58, 255, 0.18);
        border-radius: 56px;
        font-size: 18px;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.5s;
        &:hover {
            transform: scale(1.06);
        }
        `
        
    const HomeCards = styled.div`
        width: 1000px;
        padding-top: 50px;
        height: fit-content;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        margin-bottom: 32px;
        z-index: 2;
        @media (min-width: 768px) and (max-width: 1100px){
            width: 600px;
        }
        @media (max-width: 767px){
            flex-direction: column;
            margin-bottom: 10px;
        `

    const CardsColumn = styled.div`
        width: 50%;
        height: fit-content;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        margin: 0 20px;
        @media (min-width: 768px) and (max-width: 1100px){
            width: 40%;
            margin: 0 -20px;
        }
 
    `

    const BlueCard = styled.div`
        width: 480px;
        height: 328px;
        background: linear-gradient(290.69deg, #009FE3 0%, #0058DB 100%);

        box-shadow: 0px 5px 14px rgba(8, 15, 52, 0.04);
        border-radius: 18px;

        display: flex;
        flex-direction: column;
        justify-content: space-around;

        @media (min-width: 768px) and (max-width: 1100px){
            width: 350px;
            height: 328px;
        }

        @media (max-width: 767px){
            display: none;
        }
    `

    const BlueCardTitle = styled.h1`
        font-weight: 700;
        font-size: 24px;
      // line-height: 35px;
        color: #FFFFFF;
        margin-left: 50px;
        @media (min-width: 768px) and (max-width: 1100px){
            font-size: 18px;
          // line-height: 25px;
            margin-left: 30px;
        }

    `

    const BlueCardDescription = styled.p`
        font-weight: 400;
        font-size: 18px;
        width: 358px;
      // line-height: 30px;
        color: #FFFFFF;
        margin-left: 56px;
        @media (min-width: 768px) and (max-width: 1100px){
            font-size: 14px;
          // line-height: 20px;
            width: 200px;
            margin-left: 30px;
        }
    `

    const BlueCardButton = styled.div`
        width: 310px;
        height: 65px;
        background: #FFFFFF;
        box-shadow: 0px 3px 12px rgba(74, 58, 255, 0.18);
        border-radius: 56px;   
        margin-left: calc(50% - 155px);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.5s;
        &:hover {
            transform: scale(1.06);
        }
        @media (min-width: 768px) and (max-width: 1100px){
            width: 295px;
            height: 65px;
            margin-left: calc(50% - 147.5px);
            font-size: 14px;
            @media (max-width: 768px){
                display: none;
            }
        }

    `

    const LeftCircle = styled.img`
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        margin-top: 150px;
        background-color: #ECF0FF;
        backdrop-filter: blur(70.063px);
        @media (max-width: 768px){
            display: none;
        }
    `

    const RightCircle = styled.img`
        position: absolute;
        top: 0;
        right: 0;
        z-index: 0;
        background-color: #ECF0FF;
        backdrop-filter: blur(70.063px);
        @media (min-width: 768px) and (max-width: 1100px){
            display: none;
        }
        @media (max-width: 768px){
            display: none;
        }
    `

    const DownArrow = styled.img`
        position: absolute;
        top: 85vh;
        left: calc(50% - 10px);
        margin-bottom: 50px;
        width: 40px;
        cursor: pointer;
    `

    const handleArrow = () => {
        window.scrollTo({
            top: document.getElementById('homecards').offsetTop,
            behavior: 'smooth'
        })
    }

    const LabImage = styled.img`    
        height: 548px;
        @media (min-width: 768px) and (max-width: 1100px){
            width: 352px;
            height: 552px;
        }
        @media (max-width: 767px){
            display: none;
        }
    `
      
    return ( 
        <Home>
            <LeftCircle src={leftCircle} />
            <RightCircle src={rightCircle} />
            <HomeInfo>
                <HomeSpans>
                    <Fade top distance="20px">
                    <InstitutoName>
                        <Trans i18nKey="labInstitute">INSTITUTO DE QUÍMICA - UFRJ</Trans>
                    </InstitutoName>
                    </Fade>
                    <Fade top distance="30px" delay={500}>
                        <LaboratorioName>
                            <Trans i18nKey='labName'>Laboratório de Reatividade de Hidrocarbonetos, Biomassa e Catálise</Trans>
                        </LaboratorioName>
                    </Fade>
                    <Fade top distance="30px" delay={1000}>
                        <LaboratorioDescription>
                            <Trans i18nKey='labDescription'>Há mais de 25 anos desenvolvendo pesquisa e recursos humanos de qualidade na área de química</Trans>
                        </LaboratorioDescription>
                    </Fade>
                    <Fade style={{height:'50px'}}  top distance="30px" delay={1500}>
                        <HomeButton onClick={() => {window.location.href='/sobre'}}><Trans i18nKey='homeMoreButton'>Conheça</Trans></HomeButton>
                    </Fade>
                </HomeSpans>
                <DownArrow src={downArrow} onClick={() => handleArrow()} alt="down-arrow" />
            </HomeInfo>

                <HomeCards id='homecards'>
                    <CardsColumn >
                        <Fade left distance="60px" delay={300}>
                            <Card href='/sobre' title="Sobre nós" i18Title='aboutCardTitle' description='Saiba mais sobre o laboratório' i18Desc='aboutCardDesc' image={id} />
                        </Fade>
                        <Fade left distance="60px" delay={600}>
                            <Card href='/projetos' title="Produção Científica" i18Title='sciCardTitle' description="Veja nossas produções científicas" i18Desc='sciCardDesc' image={paper}/>
                        </Fade>
                    <Fade left distance="60px" delay={900}>
                        <BlueCard>
                            <BlueCardTitle><Trans i18nKey='blueCardTitle'>Seja do LARHCO</Trans></BlueCardTitle>
                            <BlueCardDescription><Trans i18nKey='blueCardDescription'>Seja um dos pesquisadores e alunos que fazem parte do LARHCO</Trans></BlueCardDescription>
                            <BlueCardButton onClick={() => {window.location.href ='/contato'}}><Trans i18nKey='blueCardButton'>Faça parte da nossa equipe</Trans></BlueCardButton>
                        </BlueCard>
                        </Fade>
                    </CardsColumn>
                    <CardsColumn>
                    <Fade right distance="60px" delay={500}>
                        <Card href='/equipe' title="Equipe" i18Title='teamCardTitle' description="Conheça nossos pesquisadores e alunos" i18Desc='teamCardDesc' image={group}/>
                        </Fade>
                    <Fade right distance="60px" delay={900}>
                        <LabImage src={labImage} height='548px' alt="" />
                        </Fade>
                    </CardsColumn>
                </HomeCards>
        </Home>
     );
}

const Card = (props) => {
    
    const Card = styled.div`
        width: 480px;
        height: 180px;
        background: #FFFFFF;
        cursor: pointer;
       
        box-shadow: 0px 5px 14px rgba(8, 15, 52, 0.04);
        border-radius: 18px;
        display: flex;
        margin-bottom: 40px;
        
        display: flex;
        align-items: center;
        justify-content: space-around;
        transition: all 0.5s;
        &:hover {
            transform: scale(1.06);
        }
        @media (min-width: 768px) and (max-width: 1100px){
            width: 350px;
            height: 200px;
        }
        @media (max-width: 767px){
            width: 350px;
            height: 150px;
        }
    `
    
    const CardImage = styled.img`
        width: 85px;
        height: 67px;
        margin-left: 30px;
        @media (max-width: 767px){
            width: 80px;
        }
    `

    const CardInfo = styled.div`
        width: 250px;
        height: 50%;
        display: flex;
        align-items: flex-start;
        justify-content: space-around;
        flex-direction: column;
        @media (min-width: 768px) and (max-width: 1100px){
            width: 200px;
        }
        @media (max-width: 767px){
            margin-left: 20px;
        }
    `

    const CardTitle = styled.h1`
        font-weight: 700;
        font-size: 24px;
        color: #1E1E1E;
        @media (max-width: 767px){
            font-size: 16px;
        }
    `

    const CardDescription = styled.p`
        font-weight: 400;
        font-size: 18px;
      // line-height: 25px;
        color: #6F6C90;
        @media (max-width: 767px){
            font-size: 16px;
        }
        @media (max-width: 767px){
            width: 180px;
            font-size: 13px;
          // line-height: 13px;
        }
    `

    return (
        <Card onClick={() => window.location.href = props.href}>
            <CardImage src={props.image} alt="card-image" />
            <CardInfo>
                <CardTitle><Trans i18nKey={props.i18Title}>{props.title}</Trans></CardTitle>
                <CardDescription><Trans i18nKey={props.i18Desc}>{props.description}</Trans></CardDescription>
            </CardInfo>
        </Card>
    );
}
 
export default Home;