import { useState, useEffect } from 'react';
import { Container, ContainerTitle, ContainerDescription, ContainerBlueTitle, ContainerItem, FormButton, FormLeft, FormInput, FormInputs, FormTextArea, ButtonsDiv, DeleteButton } from "../styles";
import styled from 'styled-components';
import editButton from './../assets/editbutton.svg'
import { Trans } from 'react-i18next';

const Supporters = () => {

    const [account, setAccount] = useState({});
    const [hasPermission, setHasPermission] = useState(false);
    const [supporters, setSupporters] = useState([]);
    const [links, setLinks] = useState([]);

    useEffect(() => {
        document.title = "Financiadores e Parceiros - LARHCO - Instituto de Química da UFRJ";

        const localStorageAccount = localStorage.getItem("account")
        const localStoragePassword = localStorage.getItem("password")

        if (localStorageAccount === "admin" && localStoragePassword === "admin") {
            setAccount({
                account: localStorageAccount,
                password: localStoragePassword
            })

            console.log(account)
setHasPermission(true)

        } else {
            setAccount({
                account: "",
                password: ""
            })

            setHasPermission(false)
        }
        
        fetch("https://api-larhco.vercel.app/api/supporters")
            .then(res => res.json())
            .then(data => {
                setSupporters(data)
            })

        fetch("https://api-larhco.vercel.app/api/links")
            .then(res => res.json())
            .then(data => {
                setLinks(data)
            }
        )
    }, [])

    const [linkTitle, setLinkTitle] = useState("");
    const [linkUrl, setLinkUrl] = useState("");
    const [linkId, setLinkId] = useState(0);
    const [linkDescription, setLinkDescription] = useState("");


    const addLink = (id) => {

        if (id !== 0){
            console.log('é pra editar', id)
            return editLink(id);
        }

        const link = {
            name: linkTitle,
            url: linkUrl,
            description: linkDescription
        }

        console.log(link)
        fetch("https://api-larhco.vercel.app/api/links", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
                },
                body: JSON.stringify(link)
            })
            .then(response => response.json())
            .then(setTimeout(() => window.location.reload(), 1500))
    }

    const deleteLink = (id) => {
        fetch(`https://api-larhco.vercel.app/api/links/${id}`, {
            method: "DELETE"
        })
        .then(response => response.json())
        .then(setTimeout(() => window.location.reload(), 1500))
    }

    const editLink = (id) => {
        const link = {
            name: linkTitle,
            url: linkUrl,
            description: linkDescription
        }

        console.log(link, id)

        fetch(`https://api-larhco.vercel.app/api/links/${id}`, {
            method: "DELETE"
        })
        .then(response => response.json())
        .then(fetch("https://api-larhco.vercel.app/api/links", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
                },
                body: JSON.stringify(link)
                })
                .then(response => response.json())
                .then(setTimeout(() => window.location.reload(), 1500)))
    }
        
    const [supporterId, setSupporterId] = useState(0);
    const [supporterName, setSupporterName] = useState("");
    const [supporterUrl, setSupporterUrl] = useState("");
    const [supporterPhoto, setSupporterPhoto] = useState("");
    const [supporterType, setSupporterType] = useState("Projetos Atuais");
    
    const handleFile = async () => {

        const file = document.getElementById('file').files[0];
        
        let body = new FormData()
        body.set('key', '8bfbdf15eed781a8475a520584a4b18c')
        body.append('image', file)
        
        return fetch('https://api.imgbb.com/1/upload', {
            method: 'POST',
            body
        })
        .then(response => response.json())
        .then(result => {
            console.log(result.data.url)
            setSupporterPhoto(result.data.url)
        }
        )
    }

    const [deleteButtonVisibility, setDeleteButtonVisibility] = useState('none');

    useEffect(() => {
        if (supporterId !== 0) {
            setDeleteButtonVisibility('flex')
        } else {
            setDeleteButtonVisibility('none')
        }
    }, [supporterId])


    const [buttonVisibility, setButtonVisibility] = useState("none")

    useEffect (() => {
        if (supporterPhoto !== "") {
            setButtonVisibility("auto")
        }
    }, [supporterPhoto])

        
    const handleSubmit = (id) => {
        if (id !== 0){
            console.log('é pra editar', id)
            return handleEdit(id);
        }

        const supporter = {
            name: supporterName,
            url: supporterUrl,
            photo: supporterPhoto,
            type: supporterType
        }

        console.log(supporter)
        
        fetch("https://api-larhco.vercel.app/api/supporters", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(supporter)
        })
        .then(res => res.json())
        .then(response => response.json())
        .then(setTimeout(() => window.location.reload(), 1500))
    }

    const handleDelete = (id) => {
        fetch(`https://api-larhco.vercel.app/api/supporters/${id}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(setTimeout(() => window.location.reload(), 1500))
    }

    const handleEdit = (id) => {
        const supporter = {
            name: supporterName,
            url: supporterUrl,
            photo: supporterPhoto,
            type: supporterType
        }

        console.log(supporter)

        fetch(`https://api-larhco.vercel.app/api/supporters/${id}`, {
            method: "DELETE"
        })
        .then(response => response.json())
        .then(fetch("https://api-larhco.vercel.app/api/supporters", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(supporter)
            })
            .then(response => response.json())
            .then(setTimeout(() => window.location.reload(), 1500)))
    }

    const SupportersDiv = styled(Container)`
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        align-content: center;
        width: 100%;
        margin: 0 auto;
        box-shadow: none;
    `

    const SupporterItem = styled.div`
        display: flex;
        flex-direction: column;
        width: 180px;
        justify-content: space-around;
        align-items: center;
    `

    const SupporterPhoto = styled.img`
        max-width: 100%;
        max-height: 130px;
        cursor: pointer;
        margin-botton: 20px;
    `

    const FormSelect = styled.select`
        background: #FFFFFF;
        border: 1px solid #514F6E;
        border-radius: 14px;
        width: 430px;
        height: 58px;
        margin: 11px;
        padding: 18px;
        &:focus {
            outline: none;
            border: 1px solid #0091DB;
            box-shadow: 0px 0px 4px #0091DB;
        }
        @media (min-width: 768px) and (max-width: 1100px){
            width: 200px;
        }
        @media (max-width: 767px){
            width: 100%;
            // margin: 11px 0;
        }
    `


    const AddPhotoButton = styled.label`
        width: 200px;
        height: 64px;
        background: linear-gradient(103.39deg, #0058DB 23.75%, #009FE3 100.26%);
        box-shadow: 0px 3px 12px rgba(74, 58, 255, 0.18);
        border-radius: 56px;    
        font-weight: 400;
        font-size: 18px;
      // line-height: 20px;
        text-align: center;
        margin-top: 20px;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    `

    const EditButtonImg = styled.img`
        position: absolute;
        right: 0;
        top: 0;
        width: 28px;
        height: 28px;
        cursor: pointer;
    `


    const AddSupporterButton = styled(FormButton)`
        width: 200px;
        display: ${buttonVisibility};
    `

    const DeleteSupporterButton = styled(DeleteButton)`
        width: 200px;
        display: ${deleteButtonVisibility};
    `

    const MiniPToEdit = styled.p`
        font-size: 14px;
        // line-height: 16px;
        color: #000000;
        margin: 0;
        margin-bottom: 10px;
        cursor: pointer;
    `

    const [LinkDeleteButtonVisibility, setLinkDeleteButtonVisibility] = useState('none');

    useEffect(() => {
        if (linkId !== 0) {
            setLinkDeleteButtonVisibility('flex')
        } else {
            setLinkDeleteButtonVisibility('none')
        }
    }, [linkId])

    const DeleteLinkButton = styled(DeleteButton)`
        width: 200px;
        display: ${LinkDeleteButtonVisibility};
    `

    const LinkButton = styled(FormButton)`
        width: 200px;
    `

    return ( 
        <div className='main-div'>

            {hasPermission && (
                <Container>
                    <ContainerTitle>Novo apoiador</ContainerTitle>
                    <FormInputs>
                            <FormInput type="text" placeholder="Título" onChange={(e) => setSupporterName(e.target.value)} value={supporterName}/>
                            <FormInput type="text" placeholder="Link" onChange={(e) => setSupporterUrl(e.target.value)} value={supporterUrl}/>
                    </FormInputs>
                    <FormSelect onChange={(e) => setSupporterType(e.target.value)} value={supporterType}>
                        <option value="Projetos Atuais">Projetos Atuais</option>
                        <option value="Projetos Anteriores">Projetos Anteriores</option>
                        <option value="Parcerias e Colaborações" >Parcerias e Colaborações</option>
                    </FormSelect>
                    <ButtonsDiv>
                        <div style={{display: 'flex', flexDirection: 'row', width: '440px', justifyContent: 'space-between'}}>
                            <DeleteSupporterButton onClick={() => handleDelete(supporterId)}>Excluir</DeleteSupporterButton>
                            <AddPhotoButton htmlFor="file">Escolher foto</AddPhotoButton>
                            <input style={{display:'none'}} type="file" name='arquivo' id="file" onChange={handleFile}/>
                        </div>
                        <AddSupporterButton onClick={() => handleSubmit(supporterId)}>Adicionar</AddSupporterButton>
                    </ButtonsDiv>
                </Container>
            )}
            {hasPermission && (
                <Container>
                    <ContainerTitle>Novo link</ContainerTitle>
                    <FormInputs>
                        <FormLeft>
                            <FormInput type="text" placeholder="Título" onChange={(e) => setLinkTitle(e.target.value)} value={linkTitle}/>
                            <FormInput type="text" placeholder="Link" onChange={(e) => setLinkUrl(e.target.value)} value={linkUrl}/>
                        </FormLeft>
                        <FormTextArea placeholder="Descrição" onChange={(e) => setLinkDescription(e.target.value)} value={linkDescription}/>      
                    </FormInputs>
                    <ButtonsDiv>
                        <DeleteLinkButton onClick={() => deleteLink(linkId)}>Excluir</DeleteLinkButton>
                        <LinkButton onClick={() => addLink(linkId)}>Adicionar</LinkButton>
                    </ButtonsDiv>
                </Container>
            )}
            

            <Container>
                <ContainerTitle><Trans i18nKey='finatuais'></Trans></ContainerTitle>
                <SupportersDiv style={{padding: "20px"}}>
                    {supporters.filter(supporter => supporter.type === "Projetos Atuais").map((supporters) => (
                        <SupporterItem>
                            {hasPermission && (
                                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around', width: '100%'}}>
                                    <MiniPToEdit style={{color: '#0091DB'}} onClick={() => {
                                        setSupporterId(supporters.id)
                                        setSupporterName(supporters.name)
                                        setSupporterUrl(supporters.url)
                                        setSupporterPhoto(supporters.photo)
                                    }}>Editar</MiniPToEdit>
                                    <MiniPToEdit style={{color: 'red'}} onClick={() => handleDelete(supporters.id)}>Excluir</MiniPToEdit>
                                </div>
                            )}
                            <SupporterPhoto src={supporters.photo} onClick={() => {window.open(supporters.url, "_blank")}}/>
                        </SupporterItem>
                    ))}
                </SupportersDiv>
            </Container>

            <Container>
                <ContainerTitle><Trans i18nKey='finanteriores'></Trans></ContainerTitle>
                <SupportersDiv style={{padding: "20px"}}>
                    {supporters.filter(supporter => supporter.type === "Projetos Anteriores").map((supporters) => (
                        <SupporterItem>
                            {hasPermission && (
                                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around', width: '100%'}}>
                                    <MiniPToEdit style={{color: '#0091DB'}} onClick={() => {
                                        setSupporterId(supporters.id)
                                        setSupporterName(supporters.name)
                                        setSupporterUrl(supporters.url)
                                        setSupporterPhoto(supporters.photo)
                                    }}>Editar</MiniPToEdit>
                                    <MiniPToEdit style={{color: 'red'}} onClick={() => handleDelete(supporters.id)}>Excluir</MiniPToEdit>
                                </div>
                            )}
                            <SupporterPhoto src={supporters.photo} onClick={() => {window.open(supporters.url, "_blank")}}/>
                        </SupporterItem>
                    ))}
                </SupportersDiv>
            </Container>

            <Container>
                <ContainerTitle><Trans i18nKey='parcerias'></Trans></ContainerTitle>
                <SupportersDiv style={{padding: "20px"}}>
                    {supporters.filter(supporter => supporter.type === "Parcerias e Colaborações").map((supporters) => (
                        <SupporterItem>
                            {hasPermission && (
                                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around', width: '100%'}}>
                                    <MiniPToEdit style={{color: '#0091DB'}} onClick={() => {
                                        setSupporterId(supporters.id)
                                        setSupporterName(supporters.name)
                                        setSupporterUrl(supporters.url)
                                        setSupporterPhoto(supporters.photo)
                                    }}>Editar</MiniPToEdit>
                                    <MiniPToEdit style={{color: 'red'}} onClick={() => handleDelete(supporters.id)}>Excluir</MiniPToEdit>
                                </div>
                            )}
                            <SupporterPhoto src={supporters.photo} onClick={() => {window.open(supporters.url, "_blank")}}/>
                        </SupporterItem>
                    ))}
                </SupportersDiv>
            </Container>
            
            <Container>
                <ContainerTitle>Links</ContainerTitle>
                {
                    links.map((link) => (
                        <ContainerItem style={{position: 'relative'}}>
                            <ContainerBlueTitle style={{cursor: 'pointer'}} onClick={() => {window.open(link.url)}}>{link.name}</ContainerBlueTitle>
                            {hasPermission && (
                                <EditButtonImg src={editButton} onClick={() => {
                                    setLinkId(link.id)
                                    setLinkTitle(link.name)
                                    setLinkUrl(link.url)
                                    setLinkDescription(link.description)
                                }}/>
                            )}
                            <ContainerDescription>{link.description}</ContainerDescription>
                        </ContainerItem>
                    ))
                }
            </Container>
        </div>
     );
}
 
export default Supporters;