import styled, {keyframes} from 'styled-components';
import logo from "../assets/logo.png";
import { useEffect, useState } from 'react';
import hamburger from "../assets/hamburger.svg";
import { useTranslation, Trans } from 'react-i18next';
import { FormButton } from '../styles'

const Header = () => {

    const [hasPermission, setHasPermission] = useState(false);

    useEffect(() => {
        const localStorageAccount = localStorage.getItem("account")
        const localStoragePassword = localStorage.getItem("password")

        if (localStorageAccount === "admin" && localStoragePassword === "admin") {
            setHasPermission(true)       

        } else {

            setHasPermission(false)
        }
    }, [])

    const HeaderDiv = styled.div`
        width: 100vw;
        height: 130px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        z-index: 2;
        background-color: #ECF0FF;
        `
        
        const Header = styled.div`
        overflow: hidden;
        width: 100vw;
        padding: 0 calc(50vw - 500px);
        height: 130px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        z-index: 3;
        background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
        @media (max-width: 1100px){
            padding: 0px 30px;
        }
    
    `


    const Link = styled.a`
        font-size: 16px;
        color: #1e1e1e;
        text-decoration: none;
        margin-left: 20px;
        transition: all 0.5s;
        &:hover {
            color: #009FE3
        }
        @media (max-width: 1100px){
            display: none;
        }
    `

    const Menu = styled.div`
        width: 1000px;
        height: 180px;
        display: flex;
        justify-content: right;
        align-items: center;
        flex-direction: row;
        z-index: 3;
        position: relative;
    `

    const [showMobileMenu, setShowMobileMenu] = useState(false);

    
    const openHamburger = () => {
        setShowMobileMenu(true);
        document.getElementById('root').style.cssText = 'position: fixed;';
    }

    const closeHamburger = () => {
        document.getElementById('root').style.cssText = 'position: relative;';
        document.getElementById('menu').style.cssText = `transform: translateX(100%);`;
        setTimeout(() => {
            setShowMobileMenu(false);
        }, 500);
    }

    const keyframe = keyframes`
        0% {
            right: -100%;
        }
        100% {
            right: 0;
        }
    `

    const MobileMenu = styled.div`
        display: none;
        @media (max-width: 1100px) {
            display: flex;
            width: 240px;
            flex-direction: column;
            height: 100vh;
            opacity: 0.96;
            z-index: 5;
            background-color: white;
            position: fixed;
            top: 0;
            right: 0;
            padding: 120px 20px;
            animation: ${keyframe} 0.5s;
            transition: 0.5s;
        }
        @media (min-width: 768px) and (max-width: 1100px) {
            width: 320px;
        }
    `

    const Vector = styled.img`
        cursor: pointer;
        display: none;
        width: 120px;
        @media (max-width: 1100px) {
            display: flex;
            margin-right: 20px;
            z-index: 6;
        }
`

        const MobileLink = styled.a`
            font-weight: 400;
            font-size: 18px;
          // line-height: 20px;
            color: #1E1E1E;
            text-decoration: none;
            margin: 10px;
        `

    const Logo = styled.img`
        width: 120px;
        cursor: pointer;
        @media (max-width: 1100px) {
            width: 300px;
        }
    `
    const ChangeLanguageDiv = styled.div`
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        margin: 45px 0;
        position: absolute;
        top: 0;
        right: 0;
        @media (max-width: 1100px){
            display: none;
        }`

    const ChangeLanguageBtn = styled.button`
        background: none;
        border: none;
        margin: 0 0 0 20px;
        text-transform: uppercase;
        color: #1E1E1E;
        transition: all 0.2s;
        cursor: pointer;
        &:hover {
            color: #0091DB !important;
        }
        `

        const { i18n } = useTranslation();

        const lngs = {
            pt: { nativeName: 'Português' },
            en: { nativeName: 'English' }
          };
    
    const LogoutButton = styled(FormButton)`
        margin-left: 20px;
        width: 100px;
        height: 40px;
        font-size: 16px;
        background-color: rgba(0, 0, 0, 0.1);
    }`

    return ( 
        <HeaderDiv>
            <Header>
            {
                showMobileMenu && 
                <div>
                    <div onClick={() => closeHamburger()} style={{cursor: 'pointer', width: '100vw', height: '100vh', position: 'absolute', top: '0', left: '0', zIndex: '2'}}/>
                    <MobileMenu id='menu'>
                            <MobileLink href="/"><Trans i18nKey='headerHome'>Início</Trans></MobileLink>
                            <MobileLink href="/sobre"><Trans i18nKey='headerAbout'>Laboratório</Trans></MobileLink>
                            <MobileLink href="/equipe"><Trans i18nKey='headerTeam'>Equipe</Trans></MobileLink>
                            <MobileLink href="/projetos"><Trans i18nKey='headerProjects'>Projetos</Trans></MobileLink>
                            <MobileLink href="/publicacoes"><Trans i18nKey='headerArticles'>Publicações</Trans></MobileLink>
                            <MobileLink href="/extensao"><Trans i18nKey='headerExtensions'>Extensão</Trans></MobileLink>
                            <MobileLink href="/apoio"><Trans i18nKey='headerSupporters'>Financiadores e Parceiros</Trans></MobileLink>
                            <MobileLink href="/contato"><Trans i18nKey='headerContact'>Contato</Trans></MobileLink>
                            { hasPermission &&
                                <MobileLink onClick={() => {
                                    localStorage.removeItem('account');
                                    localStorage.removeItem('password');
                                    window.location.href = '/login';
                                }}>Logout</MobileLink>
                        }
                    </MobileMenu>
                    </div>
                }
                <Logo src={logo} onClick={() => window.location.href = '/'} alt="logo" />
                <Menu>
                    <ChangeLanguageDiv>
                    {Object.keys(lngs).map((lng) => (
                        <ChangeLanguageBtn key={lng} style={{ fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal', color: i18n.resolvedLanguage === lng ? '#0091DB' : '#1E1E1E' }} type="submit" onClick={() => {i18n.changeLanguage(lng); window.location.reload()}}>
                            {lngs[lng].nativeName}
                        </ChangeLanguageBtn>
                        ))}
                    </ChangeLanguageDiv>
                    <Link href="/"><Trans i18nKey='headerHome'>Início</Trans></Link>
                    <Link href="/sobre"><Trans i18nKey='headerAbout'>Laboratório</Trans></Link>
                    <Link href="/equipe"><Trans i18nKey='headerTeam'>Equipe</Trans></Link>
                    <Link href="/projetos"><Trans i18nKey='headerProjects'>Projetos</Trans></Link>
                    <Link href="/publicacoes"><Trans i18nKey='headerArticles'>Publicações</Trans></Link>
                    <Link href="/extensao"><Trans i18nKey='headerExtensions'>Extensão</Trans></Link>
                    <Link href="/apoio"><Trans i18nKey='headerSupporters'>Financiadores e Parceiros</Trans></Link>
                    <Link href="/contato"><Trans i18nKey='headerContact'>Contato</Trans></Link>
                    { hasPermission &&
                        <Link style={{cursor: 'pointer'}} onClick={() => {
                            localStorage.removeItem('account');
                            localStorage.removeItem('password');
                            window.location.href = '/login';
                        }}>Logout</Link>
                    }
                </Menu>
                <Vector src={hamburger} onClick={() => showMobileMenu ? closeHamburger() : openHamburger()}/>
            </Header>
        </HeaderDiv>
     );
}
 
export default Header;