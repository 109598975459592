import Header from './pages/Header';
import Homepage from './pages/Homepage';
import Footer from './pages/Footer';
import About from './pages/About';
import Project from './pages/Project';
import Login from './pages/Login';
import Contact from './pages/Contact';
import Team from './pages/Team';
import Supporters from './pages/Supporters';
import Extensions from './pages/Extensions';
import Publications from './pages/Publications';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';

function App() {
  return (
    
    <div className="App">
      <Header />
      <Router>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/sobre" element={<About />} />
          <Route path="/projetos" element={<Project />} />
          <Route path="/contato" element={<Contact />} />
          <Route path="/login" element={<Login />} />
          <Route path='/equipe' element={<Team />} />
          <Route path='/apoio' element={<Supporters />} />
          <Route path='/extensao' element={<Extensions />} />
          <Route path='/publicacoes' element={<Publications />} />
        </Routes>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
