import {Container, ContainerTitle, ContainerDescription, hasPermissionContainer, ContainerBlueTitle, ContainerItem, FormButton, FormLeft, FormInput, FormInputs, FormTextArea, ButtonsDiv, DeleteButton} from '../styles'
import styled from "styled-components";
import { Trans } from 'react-i18next';

import { useState, useEffect } from "react";

const Publications = () => {

    const [account, setAccount] = useState({});

    const [hasPermission, setHasPermission] = useState(false);

    const [publications, setPublications] = useState([]);

    const [articles, setArticles] = useState([]);
    const [books, setBooks] = useState([]);
    const [bookChapter, setBookChapter] = useState([]);
    const [patent, setPatent] = useState([]);

    useEffect(() => {
        document.title = "Publicações - LARHCO - Instituto de Química da UFRJ";

        const localStorageAccount = localStorage.getItem("account")
        const localStoragePassword = localStorage.getItem("password")

        if (localStorageAccount === "admin" && localStoragePassword === "admin") {
            setAccount({
                account: localStorageAccount,
                password: localStoragePassword
            })

            console.log(account)
setHasPermission(true)

        } else {
            setAccount({
                account: "",
                password: ""
            })

            setHasPermission(false)
        }
        
        fetch("https://api-larhco.vercel.app/api/publications")
            .then(res => res.json())
            .then(data => {
                setPublications(data)
                const articles = data.filter(publication => publication.category === "article")
                const books = data.filter(publication => publication.category === "book")
                const bookChapter = data.filter(publication => publication.category === "bookChapter")
                const patent = data.filter(publication => publication.category === "patent")
                setBookChapter(bookChapter.reverse())
                setArticles(articles.reverse())
                setBooks(books.reverse())
                setPatent(patent.reverse())
            })
            .then(() => {
                console.log(articles)
                console.log(books)
            })
    }, [])

    const [publicationID, setPublicationID] = useState(0);
    const [publicationName, setPublicationName] = useState("");
    const [publicationDescription, setPublicationDescription] = useState("");
    const [publicationLink, setPublicationLink] = useState("");
    const [publicationType, setPublicationType] = useState("article");
    
    const addPublication = (id) => {

        if (id !== 0){
            console.log('é pra editar', id)
            return editPublication(id);
        }

        const publication = {
            name: publicationName,
            description: publicationDescription,
            url: publicationLink,
            category: publicationType
        }
        console.log(publication)
        fetch("https://api-larhco.vercel.app/api/publications", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
                },
                body: JSON.stringify(publication)
            })
            .then(response => response.json())
            .then(setTimeout(() => window.location.reload(), 1500))
    }

    const deletePublication = (id) => {
        fetch(`https://api-larhco.vercel.app/api/publications/${id}`, {
            method: "DELETE"
        })
        .then(response => response.json())
        .then(setTimeout(() => window.location.reload(), 1500))
    }

    const editPublication = (id) => {
        const publication = {
            name: publicationName,
            description: publicationDescription,
            url: publicationLink,
            category: publicationType
        }
        console.log(publication, id)
        // fetch(`https://api-larhco.vercel.app/api/publications/${id}`, {
        //     method: "PUT",
        //     headers: {
        //         "Content-Type": "application/json"
        //         },
        //         body: JSON.stringify(publication)
        //     })
        //     .then(response => response.json())
        //     .then(data => console.log(data))

        fetch(`https://api-larhco.vercel.app/api/publications/${id}`, {
            method: "DELETE"
        })
        .then(response => response.json())
        .then(fetch("https://api-larhco.vercel.app/api/publications", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
                },
                body: JSON.stringify(publication)
                })
                .then(response => response.json())
                .then(setTimeout(() => window.location.reload(), 1500)))
    }
            // .then(setTimeout(() => window.location.reload(), 1500))



    const FormSelect = styled.select`
        background: #FFFFFF;
        border: 1px solid #514F6E;
        border-radius: 14px;
        width: 430px;
        height: 58px;
        // margin: 11px;
        padding: 18px;
        &:focus {
            outline: none;
            border: 1px solid #0091DB;
            box-shadow: 0px 0px 4px #0091DB;
        }
        @media (min-width: 768px) and (max-width: 1100px){
            width: 200px;
        }
        @media (max-width: 767px){
            width: 100%;
            // margin: 11px 0;
        }
    `

    const PubsButton = styled(FormButton)`
        width: 230px;
    `
    const [LinkDeleteButtonVisibility, setLinkDeleteButtonVisibility] = useState('none');

    const PubsDelete = styled(DeleteButton)`
        width: 230px;
        display: ${LinkDeleteButtonVisibility};
    `


    useEffect(() => {
        if (publicationID !== 0) {
            setLinkDeleteButtonVisibility('flex')
        } else {
            setLinkDeleteButtonVisibility('none')
        }
    }, [publicationID])

    return ( 
        <div className='main-div'>

            {hasPermission && (
                <Container>
                    <ContainerTitle>Nova publicação</ContainerTitle>
                    <FormInputs>
                        <FormLeft>
                            <FormInput type="text" placeholder="Título" onChange={(e) => setPublicationName(e.target.value)} value={publicationName}/>
                            <FormInput type="text" placeholder="Link" onChange={(e) => setPublicationLink(e.target.value)} value={publicationLink}/>
                            <FormSelect onChange={(e) => setPublicationType(e.target.value)} value={publicationType}>
                                <option value="article" >Artigos</option>
                                <option value="book" >Livros</option>
                                <option value="bookChapter" >Capítulos de livros</option>
                                <option value="patent" >Patentes</option>
                            </FormSelect>
                        </FormLeft>
                        <FormTextArea style={{height: '218px'}} placeholder="Descrição" onChange={(e) => setPublicationDescription(e.target.value)} value={publicationDescription}/>   
                    </FormInputs>
                    <ButtonsDiv>
                        <PubsDelete onClick={() => deletePublication(publicationID)}>Excluir</PubsDelete>
                        <PubsButton onClick={() => addPublication(publicationID)}>Adicionar</PubsButton>
                    </ButtonsDiv>
                </Container>
            )}
            
            <Container>
                <ContainerTitle><Trans i18nKey='artigos'></Trans></ContainerTitle>
                {
                    articles.map((publication) => (
                        <ContainerItem onClick={
                            () => {
                                setPublicationName(publication.name)
                                setPublicationDescription(publication.description)
                                setPublicationLink(publication.url)
                                setPublicationType(publication.category)
                                setPublicationID(publication.id)
                            }
                        }>
                            <ContainerBlueTitle>{publication.name}</ContainerBlueTitle>
                            <ContainerDescription>{publication.description}</ContainerDescription>
                        </ContainerItem>
                    ))
                }

            </Container>
            <Container>
                <ContainerTitle><Trans i18nKey='books'></Trans></ContainerTitle>
                {
                    books.map((publication) => (
                        <ContainerItem onClick={
                            () => {
                                setPublicationName(publication.name)
                                setPublicationDescription(publication.description)
                                setPublicationLink(publication.url)
                                setPublicationType(publication.category)
                                setPublicationID(publication.id)
                            }
                        }>
                            <ContainerBlueTitle>{publication.name}</ContainerBlueTitle>
                            <ContainerDescription>{publication.description}</ContainerDescription>
                        </ContainerItem>
                    ))
                }
            </Container>

            <Container>
                <ContainerTitle><Trans i18nKey='capitulos'></Trans></ContainerTitle>
                {
                    bookChapter.map((publication) => (
                        <ContainerItem onClick={
                            () => {
                                setPublicationName(publication.name)
                                setPublicationDescription(publication.description)
                                setPublicationLink(publication.url)
                                setPublicationType(publication.category)
                                setPublicationID(publication.id)
                            }
                        }>
                            <ContainerBlueTitle>{publication.name}</ContainerBlueTitle>
                            <ContainerDescription>{publication.description}</ContainerDescription>
                        </ContainerItem>
                    ))
                }
            </Container>

            <Container>
                <ContainerTitle><Trans i18nKey='patent'></Trans></ContainerTitle>
                {
                    patent.map((publication) => (
                        <ContainerItem onClick={
                            () => {
                                setPublicationName(publication.name)
                                setPublicationDescription(publication.description)
                                setPublicationLink(publication.url)
                                setPublicationType(publication.category)
                                setPublicationID(publication.id)
                            }
                        }>
                            <ContainerBlueTitle>{publication.name}</ContainerBlueTitle>
                            <ContainerDescription>{publication.description}</ContainerDescription>
                        </ContainerItem>
                    ))
                }
            </Container>
        </div>
        );
}

export default Publications;