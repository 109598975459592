import { useState, useEffect } from 'react';
import styled from 'styled-components'
import background from "../../assets/count-background.png";
import Fade from 'react-reveal/Fade';
import { Trans } from 'react-i18next';

const HomeCount = () => {

    const Count = styled.div`
        width: 100vw;
        height: 530px;
        background-image: url(${background});
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        z-index: 2;
        @media (max-width: 767px){
            height: 815px;
        }
    `

    const CountInfo = styled.div`
        width: 1000px;
        heigth: 200px;
        border-radius: 35px;
        background: #FFFFFF;
        box-shadow: 0px 26.7864px 117.19px rgba(32, 36, 138, 0.06);
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: row;
        @media (min-width: 768px) and (max-width: 1100px){
            width: 480px;
            padding: 20px;
            flex-wrap: wrap;
        }
        @media (max-width: 768px){
            width: 100%;
            flex-direction: column;
            padding: 20px 50px 40px;
            border-radius: 25px;
            height: 600px;
        }
    `

    const [projectsCount, setProjectsCount] = useState(0);
    const [articlesCount, setArticlesCount] = useState(0);
    const [equipsCount, setEquipsCount] = useState(0);
    const [teamCount, setTeamCount] = useState(0);

    useEffect(() => {
        fetch("https://api-larhco.vercel.app/api/team")
            .then(response => response.json())
            .then(data => setTeamCount(data.length))

        fetch("https://api-larhco.vercel.app/api/publications")
            .then(response => response.json())
            .then(data => setArticlesCount(data.length))

        fetch("https://api-larhco.vercel.app/api/equips")
            .then(response => response.json())
            .then(data => setEquipsCount(data.length))

        fetch("https://api-larhco.vercel.app/api/projects")
            .then(response => response.json())
            .then(data => setProjectsCount(data.length))
    }, [])

    return ( 
        <Count>
        <Fade top distance="50px" delay={200} duration={1000}>
                <CountInfo>
                    <SingleInfo endpoint="projetos" number={projectsCount} description="Projetos de pesquisa" i18n='projectCount'/>
                    <SingleInfo endpoint="publicacoes" number={articlesCount} description="Artigos publicados" i18n='articleCount' />
                    <SingleInfo endpoint="equipe" number={teamCount} description="Professores e alunos" i18n='teamCount'/>
                    <SingleInfo endpoint="sobre" number={equipsCount} description="Equipamentos laboratoriais" i18n='equipsCount'/>
                </CountInfo>
            </Fade>
        </Count>
     );
}

const SingleInfo = (props) => {

    const SingleInfo = styled.div`
        width: 150px;
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        cursor: pointer;
    `

    const InfoNumber = styled.h1`
        font-weight: 700;
        font-size: 45px;
      // line-height: 62px;
        text-align: center;
        color: #1E1E1E;
    `
        
    const InfoDescription = styled.p`
        font-weight: 400;
        font-size: 20px;
        
        // line-height: 20px;
        text-align: center;
        color: #514F6E;

        @media (max-width: 767px){
            font-size: 14px;
            width: 110px;
        }
    `

    return (
        <SingleInfo onClick={() => window.open(props.endpoint, "_blank")} >
            <InfoNumber>{props.number}</InfoNumber>
            <InfoDescription><Trans i18nKey={props.i18n}>{props.description}</Trans></InfoDescription>
        </SingleInfo>
    )
}
 
export default HomeCount;